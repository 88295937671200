import {
    FETCH_RATE_END,
    FETCH_RATE_START,UPDATE_RATE_END,UPDATE_RATE_START
} from "../action/rateCard";

const initState = {
    rateCard: null,
    error: null,
    fetchProg: false,
    updateProg: false,
};

const rateCardReducer = (state = initState, action) => {
    let payload = action.payload;
    switch (action.type) {
        case FETCH_RATE_START:
            return { ...state, fetchProg: true };
        case FETCH_RATE_END:
            let _data = {};
            let _error = null;
            if (payload.data !== undefined) {
                _data = payload.data;
            }

            if (payload.error !== undefined) {
                _error = payload.error;
            }
            return { ...state, rateCard: _data, error: _error, fetchProg: false };

        case UPDATE_RATE_START:
            return { ...state, updateProg: true };
        case UPDATE_RATE_END:
            let _updateData = {};
            let _updateError = null;
            if (payload.data !== undefined) {
                _updateData = payload.data;
            }

            if (payload.error !== undefined) {
                _updateError = payload.error;
            }
            return { ...state, rateCard: _updateData, error: _updateError, updateProg: false };

        default:
            return { ...state };
    }
};
export default rateCardReducer;
