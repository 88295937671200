import { createStyles } from '@material-ui/core/styles';

export const useStyle = function (theme) {

    return createStyles({
        "@global": {
            "html,body,#root": {
                margin: 0,
                height: '100%'
            }
        },
        root:{
            display:"flex"
        },
        content: {
            flexGrow: 1,
            paddingLeft: 0,
            paddingRight: 0,
            // width:"85%",
            maxWidth:"none"
        },
        typo:{
            padding:"15px 15px 0px 15px",
            textAlign:"end"
        },
        toolbar: theme.mixins.toolbar,

    })
}