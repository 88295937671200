import {createStyles} from '@material-ui/core/styles';

export const useStyle =  function(theme){
    const {breakpoints}     =   theme;

    return createStyles({
        main:{
            height:"100vh",
            display:"flex", 
            backgroundImage: "linear-gradient(to right, #f3f3f3d4 , #63b765)"
        },
        paper:{
            padding:"15px",
            margin:"auto",
            width:"35%",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            textAlign:"center",
            [breakpoints.down('xs')]: {
                width: "100%",
                margin:"inherit",
            },
            [breakpoints.between('sm','md')]: {
                width:"50%",
            },
        },
        input_field:{
            width:"100%",
            marginBottom:"10px"
        },
        title:{
            marginBottom:"10px",
            color:"#70BD72"
        },
        logo:{
            width:"160px",
            height:"100px",
            color:"#70BD72",
           
        },
        button:{
            backgroundColor:"#70BD72 !important",
            color:"#ffff",
            width:"100%"
        },
        error:{
            textAlign:"end",
            color:"red"
        },
        typo:{
            marginBottom:"15px",
            fontWeight:"bold"
        }
    })
}