import { createMuiTheme } from '@material-ui/core/styles';

const primary_theme = {
    'palette': {
        'primary': {
            'main': '#70BD72',
            'lightLogo': "#FFC95F"
        },
        'secondry': {
            'main': "#64B766"
        }
    },
    'spacing': 8,
    'textColor': "#ffff"
}
const theme = function (type) {
    var theme = null;
    switch (type) {
        case "Primary":
            theme = primary_theme;
            break;
        default:
            theme = primary_theme;
            break;
    }
    return createMuiTheme(theme);
};
export default theme