import {fade, createStyles } from '@material-ui/core/styles';

export const useStyle = function (theme) {
    const {palette,breakpoints}     =   theme;

    return createStyles({
        root: {
            width: '100%',
            padding: '15px',
            overflow:"scroll",
            [breakpoints.down('sm')]:{
                padding:"0px"
            },
        },
        paper: {
            width: '100%',
            padding: '15px',
            [breakpoints.down('sm')]:{
                overflow:"scroll",
            },
        },
        title: {
            fontWeight: 600,
            display:"inline"
        },
        headDiv: {
            marginBottom:"15px"
        },
        mainhead: {
            background:palette.secondry.main
        },
        tableHeader: {
            // color: "#ffff",
            fontWeight: '600',
            fontSize: '14px'
        },
        tabledata: {
            // padding: "0px 0px 0px 16px"
        },
        addButton:{
            float:"right"
        },
        spacer: {
            flex: '1 1 ',
        },
        actions: {
             display: "contents"
        },
        search: {
            position: 'relative',
            borderBottom: "1px solid #4D5FC6",
            borderRadius: 'none',
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
              backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '100%',
        },
          searchIcon: {
            width: theme.spacing(7),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.main
          },
          inputRoot: {
            color: 'inherit',
          },
          inputInput: {
            padding: theme.spacing(1, 1, 1, 7),
            transition: theme.transitions.create('width'),
            width: '100%',
          },
    })
}