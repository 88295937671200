import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import List from "./List";
import Edit from "./Edit";
// import * as UserAction from "../../service/action/user";
class RateCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    //   UNSAFE_componentWillMount() {
    //     const token = sessionStorage.getItem("authToken");
    //     if (token === undefined || token === null) {
    //       this.props.history.push("/");
    //       return;
    //     }
    //     this.props.getProfile();
    //   }

    render() {
        return (
            <div>
                <Switch>
                    <Route path={`${this.props.match.path}/list`} component={List} />
                    <Route path={`${this.props.match.path}/:id`} component={Edit} />
                    <Redirect exact path={`${this.props.match.path}`} to={`${this.props.match.path}/list`} />
                </Switch>
            </div>
        );
    }
}

export default connect(
    (state, props) => {
        //State to Prop
        return {
            userReducer: state.userReducer,
        };
    },
)(withRouter(RateCard));
