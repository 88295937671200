import React, {Fragment }from 'react';
import {connect} from "react-redux";

import { AppBar, Toolbar, Typography,CssBaseline,withWidth} from '@material-ui/core';
import {compose} from "recompose";
import {withStyles} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {withRouter} from "react-router-dom";
// import AccountCircle from '@material-ui/icons/AccountCircle';
import './style'


class Appbar extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            'user':'',
        }
    } 
    render(){
        const classes   =   this.props.classes; 
        const auth_user = this.props.authReducer.auth_user;
        if (auth_user === null) {
            return null
        }
        return(             
            <Fragment>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <div className={classes.menuButton}>
                            {this.props.menu}
                        </div>
                        <Typography variant="h6" noWrap>
                            Quote Pricing
                        </Typography>
                    <Typography variant="body1" className={classes.typo}>{auth_user.name + "(" + process.env.REACT_APP_ENV + ")"}</Typography>
                    </Toolbar>
                </AppBar>
            </Fragment> 
        );
    }
}
 
export default compose(
    withRouter,
    withStyles(useStyles),
    withWidth()
)(connect((state, props) => {
    //State to Prop
    return {
        authReducer: state.authReducer,
    };
}, {
})(Appbar))