import { createStyles } from '@material-ui/core/styles';

export const useStyle = function (theme) {
    const {breakpoints} = theme;
    return createStyles({
        root: {
            width: '100%',
            padding: '15px',
            overflow:"auto",
            [breakpoints.down('sm')]:{
                padding:"0px"
            },
        },
        paper:{
            padding:"15px",
            width:"100%",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            
        },
        breadcrumbs:{
            marginBottom:"10px"
        },
        input_field:{
            width:"98%",
            marginBottom:"10px",
            [breakpoints.down('md')]:{
                width:"100%",
                marginBottom:"0px"
            },
        },
        formControl:{
            width:"100%",
            marginBottom:"5px",
            [breakpoints.down('md')]:{
                width:"100%",
                marginBottom:"15px"
            },
        },
        error:{
            textAlign:"end",
            color:"red"
        }
        
    })
}