import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { Container, IconButton, withWidth } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import { compose } from "recompose";
import { useStyle } from "./style";
import ApplicationBar from "./common/appBar";
import Drawer from "./common/drawer";
import RateCard from "./rateCard";
import Users from "./users";
import Quotes from "./quotes";
import ChangePassword from "./changePassword";
import * as AuthAction from "../../service/action/auth";
import { Menu } from '@material-ui/icons';

class Application extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "showDrawer":false
        };

        this.menuIconChange = this.menuIconChange.bind(this);
        this.handleDrawer = this.handleDrawer.bind(this);
    }

    componentDidMount() {
        this.props.getAuth();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.authReducer.getAuthProg === true && nextProps.authReducer.getAuthProg === false) {
            if (nextProps.authReducer.error !== null) {
                this.props.history.push("/");
            }
        }

        if (this.props.authReducer.auth_user !== null && nextProps.authReducer.auth_user === null) {
            this.props.history.push("/");
        }
    }
    handleDrawer(){
        this.setState({showDrawer:!this.state.showDrawer})
    }
    menuIconChange() {

        var drawerIcon = (
            <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={this.handleDrawer}
            >
                <Menu />
            </IconButton>
        )

        var width = this.props.width;
        if (width === 'xs' || width === 'sm' || width === 'md') {
            return drawerIcon;
        }
        return;
    }

    render() {
        let classes = this.props.classes;
        const auth_user = this.props.authReducer.auth_user;
        if (auth_user === null) {
            return null
        }
        return (
            <div className={classes.root}>
                <ApplicationBar menu={this.menuIconChange()} ></ApplicationBar>
                {/* <div style={{ width: "14%" }}> */}
                    <Drawer showDrawer={this.state.showDrawer} handleDrawer={this.handleDrawer}/>
                {/* </div> */}
                <Container className={classes.content}>
                    <div className={classes.toolbar} />
                    <Switch>
                        <Route path={`${this.props.match.path}/rate`} component={RateCard} />
                        <Route path={`${this.props.match.path}/user`} component={Users} />
                        <Route path={`${this.props.match.path}/quote`} component={Quotes} />
                        <Route path={`${this.props.match.path}/change/password`} component={ChangePassword} />
                        <Redirect path={`${this.props.match.path}`} to={`${this.props.match.path}/quote`} />
                    </Switch>
                </Container>
            </div>
        );
    }
}

export default compose(
    withRouter,
    withStyles(useStyle),
    withWidth()
)(connect((state, props) => {
    //State to Prop
    return {
        authReducer: state.authReducer,
    };
}, {
    getAuth: AuthAction.getAuthUser
})(Application))
