import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { logger } from "redux-logger";
import promise from "redux-promise-middleware";
import userReducer from "../service/reducer/userReducer";
import authReducer from "../service/reducer/authReducer";
import rateCardReducer from "../service/reducer/rateCardReducer";
import snackBarAlertReducer from "../service/reducer/snackBarAlert";
import quoteReducer from "../service/reducer/quoteReducer";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const rootReducer = combineReducers({
  userReducer,
  authReducer,
  rateCardReducer,
  snackBarAlertReducer,
  quoteReducer
});
export const middlewares = [promise, thunk, logger]; 
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
