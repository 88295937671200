import React from "react";
import { connect } from "react-redux";
import {
    Table, TableBody, TableCell,
    TableHead, TableRow, TableFooter,
    TablePagination, Typography,
    Paper, Button, Tooltip, Toolbar, InputBase,TableContainer, Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { compose } from "recompose";
import { useStyle } from "./style";
import { Search as SearchIcon } from "@material-ui/icons";
import TablePaginationModel from "../../common/Pagination";
import * as QuoteAction from "../../../../service/action/quotes";
class QuoteList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'quoteList': [],
            'page': 0,
            'rowsPerPage': 10,
            'count': 0,
            'search_query': '',
            'fetchPage': 1
        };
        this.fetchData = this.fetchData.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData() {
        const me = this;
        let postData = {
            'search_query': me.state.search_query,
            'page': me.state.fetchPage
        }
        this.props.fetch(postData);
    }
    handleChangePage(event, newPage) {
        var me = this;

        me.setState({ 'page': newPage }, function () {
            let count = me.state.quoteList.length;
            if (count - me.state.rowsPerPage * (me.state.page + 1) < me.state.rowsPerPage) {
                me.setState({ 'fetchPage': me.state.fetchPage + 1 }, function () {
                    me.fetchData()
                })
            }
        });

    }
    handleChangeRowsPerPage(event) {
        var me = this;
        // me.state.filter.page_size = parseInt(event.target.value, 10);
        me.setState({ rowsPerPage: parseInt(event.target.value, 10) }, function () {
            let count = this.state.quoteList.length;
            if (count - this.state.rowsPerPage * (this.state.page + 1) < this.state.rowsPerPage) {
                me.fetchData()
            }
        });


    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.quoteReducer.fetchProg === true &&
            nextProps.quoteReducer.fetchProg === false
        ) {
            if (nextProps.quoteReducer.error === null) {
                let data = nextProps.quoteReducer.quotes;
                let totalQuotes = nextProps.quoteReducer.totalQuotes;
                this.setState({ quoteList: data, count: totalQuotes });
                // this.setState({ quoteList: data });
            }
        } else {
            /* eslint-disable no-unused-vars */
            let error = nextProps.quoteReducer.error;
            /* eslint-enable no-unused-vars */
        }
    }

    render() {
        let classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Paper elevation={3} className={classes.paper}>
                    <Grid container>
                        <Grid item lg={1} md={1} sm={12} xs={12}>
                            <Typography className={classes.title} variant="h6" id="tableTitle">
                                Quotes
                            </Typography>
                        </Grid>
                        <Grid item lg={8} md={8} sm={7} xs={7}>
                        <Toolbar style={{ display: "inline-block" }}>
                            <div className={classes.spacer} />
                            <div className={classes.actions}>
                                <Tooltip title="search by school name">
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase
                                            placeholder="Search…"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            inputProps={{ 'aria-label': 'Search' }}
                                            value={this.state.search_query}
                                            onChange={(event) => {
                                                const me = this;
                                                // state.filter['search_query'] = event.target.value
                                                this.setState({ search_query: event.target.value }, function () {
                                                    me.fetchData();
                                                })
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </Toolbar>
                        </Grid>
                        <Grid item lg={3} md={3} sm={5} xs={5}>
                            <Button variant="contained" color="primary" className={classes.addButton} onClick={() => {
                                this.props.history.push("/app/quote/create")
                            }}>
                                Add Quote
                            </Button>
                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table aria-label="table" className={classes.table}  >
                            <TableHead className={classes.mainhead}>
                                <TableRow  >
                                    <TableCell align="left" className={classes.tableHeader}><Typography >School Name</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >School Location</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Total Learners</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Total Classes</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Devices</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >ToolBox</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Final Price per student</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Assesment(Pre/mid/end)</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Created By</Typography></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.quoteList.length === 0 && <TableRow><TableCell colSpan={14} style={{ textAlign: "center" }}><Typography >No Records Found</Typography></TableCell></TableRow>}
                                {/* {this.state.quoteList.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)} */}
                                {this.state.quoteList.length !== 0 && this.state.quoteList.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row, i) => {
                                    const devices = row.proposed_quote[row.proposed_quote.findIndex(item => item.name === 'Alexa Echodot device')].no_of_unit;
                                    const toolBox = row.proposed_quote[row.proposed_quote.findIndex(item => item.name === 'ToolBox')].no_of_unit;
                                    const assessPre = row.proposed_quote[row.proposed_quote.findIndex(item => item.name === 'Assessment (pre)')].no_of_unit;
                                    const assessMid = row.proposed_quote[row.proposed_quote.findIndex(item => item.name === 'Assessment (mid)')].no_of_unit;
                                    const assessEnd = row.proposed_quote[row.proposed_quote.findIndex(item => item.name === 'Assessment (end)')].no_of_unit;

                                    return <TableRow key={i} style={{ cursor: "pointer" }} onClick={() => {
                                        // this.props.history.push("/app/quote/" + row._id, row)
                                        this.props.history.push("/app/quote/view", row)
                                    }}>
                                        <TableCell align="left" className={classes.tabledata}>{row.school_name}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.school_location}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.total_learners}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.total_classes}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{devices}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{toolBox}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.final_price_per_student}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{assessPre + " / " + assessMid + " / " + assessEnd}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.created_by}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        classes={{ root: classes.paginate }}
                                        rowsPerPageOptions={[2, 5, 10, 15]}
                                        colSpan={14}
                                        count={this.state.count}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationModel}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
            </div >
        );
    }
}

export default compose(
    withStyles(useStyle)
)(connect((state, props) => {
    //State to Prop
    return {
        quoteReducer: state.quoteReducer,
    };
}, {
    fetch: QuoteAction.fetch
})(QuoteList))
