import {createStyles} from '@material-ui/core/styles';

export const useStyle =  function(theme){
    const {breakpoints}     =   theme;
    return createStyles({
        paper:{
            width:"50%",
            [breakpoints.down('md')]:{
                width:"100%"
            },
        },
        formControl:{
            width:"100%",
            marginBottom:"10px"
        }

    })
}