import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/styles';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Button, Typography,
} from '@material-ui/core';
import { useStyle } from "./style";
class ModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let props = this.props;
    let classes = this.props.classes;
    return (
      <Dialog open={props.open} onClose={() => { props.onClose() }} aria-labelledby="form-dialog-title" classes={{ paper: classes.paper }}>
        <DialogTitle id="form-dialog-title"><Typography variant="h5">{props.title}</Typography></DialogTitle>
        <DialogContent dividers >
          <Typography variant="h6">{props.body}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            props.onClose()
          }} color="primary" variant="contained">
            Cancel
        </Button>
          <Button onClick={() => { props.onSubmit() }} color="primary" variant="contained">
            {props.buttonText}
        </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ModalComponent.propTypes = {
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  body:PropTypes.string,
  buttonText:PropTypes.string
};

ModalComponent.defaultProps = {
  onSubmit: () => null,
  title: "Modal",
  onClose: () => null,
  open: false,
  body: "Modal Body",
  buttonText:"Confirm"
};

export default withStyles(useStyle)(ModalComponent);
