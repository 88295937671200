import {
    GET_USER_END,GET_USER_START, FETCH_USER_END, FETCH_USER_START, ADD_USER_END,
    ADD_USER_START, UPDATE_USER_END, UPDATE_USER_START,CHNAGE_PASSWORD_END,CHANGE_PASSWORD_START
} from "../action/user";

const initState = {
    user: null,
    error: null,
    getUser: false,
    users: [],
    fetchProg: false,
    addProg: false,
    updateProg: false,
    changePasswordProg: false,
};

const userReducer = (state = initState, action) => {
    let payload = action.payload;
    switch (action.type) {
        case GET_USER_START:
            return { ...state, getUser: true };
        case GET_USER_END:
            let _user = {};
            let _error = null;
            if (payload.data !== undefined) {
                _user = payload.data;
            }

            if (payload.error !== undefined) {
                _error = payload.error;
            }
            return { ...state, user: _user, error: _error, getUser: false };

        case FETCH_USER_START:
            return { ...state, fetchProg: true };
        case FETCH_USER_END:
            let _data = {};
            let _fetchError = null;
            if (payload.data !== undefined) {
                _data = payload.data;
            }

            if (payload.error !== undefined) {
                _fetchError = payload.error;
            }
            return { ...state, users: _data, error: _fetchError, fetchProg: false };

        case ADD_USER_START:
            return { ...state, addProg: true };
        case ADD_USER_END:
            let _addError = null;
            if (payload.error !== undefined) {
                _addError = payload.error;
            }
            return { ...state, error: _addError, addProg: false };

        case UPDATE_USER_START:
            return { ...state, updateProg: true };
        case UPDATE_USER_END:
            let _updateError = null;
            if (payload.error !== undefined) {
                _updateError = payload.error;
            }
            return { ...state, error: _updateError, updateProg: false };

        case CHANGE_PASSWORD_START:
            return { ...state, changePasswordProg: true };
        case CHNAGE_PASSWORD_END:
            let _chnagePassError = null;
            if (payload.error !== undefined) {
                _chnagePassError = payload.error;
            }
            return { ...state, error: _chnagePassError, changePasswordProg: false };

        default:
            return { ...state };
    }
};
export default userReducer;
