import React from "react";
import { connect } from "react-redux";
import {Link as RouterLink} from "react-router-dom"
import { Button, FormControl, InputLabel, OutlinedInput,Breadcrumbs,Link,
    Paper, Typography, CardActions } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import { compose } from "recompose";
import { useStyle } from "./style";
import * as RateCardAction from "../../../../service/action/rateCard"

class RateCardEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "itemId": null,
            "item": "",
            "item_error": "",
            "profit_amount_ref": "",
            "profit_amount_ref_error": "",
            "final_price": "",
            "final_price_error": "",
            "cost": "",
            "cost_error": "",
            "profit_percent": "",
            "profit_percent_error": "",
            "handling_charge": "",
            "handling_charge_error": "",
            "profit_amount": "",
            "profit_amount_error": "",
            "total": "",
            "total_error": "",
            "gst_percent": "",
            "gst_percent_error": "",
            "gst_amount": "",
            "gst_amount_error": "",
            "min_order_qty": "",
            "min_order_qty_error": "",
            "min_order_value": "",
            "min_order_value_error": "",
            "min_profit": "",
            "min_profit_error": "",
            "remarks": "",
            "remarks_error": "",
        };
        this.updateItem = this.updateItem.bind(this);
        this.priceComputation = this.priceComputation.bind(this);
    }
    componentDidMount() {
        let data = this.props.location.state;
        this.setState({
            itemId: data._id,
            item: data.item,
            profit_amount_ref: data.profit_amount_ref,
            final_price: data.final_price,
            cost: data.cost,
            profit_percent: data.profit_percent,
            handling_charge: data.handling_charge,
            profit_amount: data.profit_amount,
            total: data.total,
            gst_percent: data.gst_percent,
            gst_amount: data.gst_amount,
            min_order_qty: data.min_order_qty,
            min_order_value: data.min_order_value,
            min_profit: data.min_profit,
            remarks: data.remarks,
        });
    }

    updateItem() {
        let me = this;
        let item = me.state.item;
        if (item === null || item === undefined || item.length === 0) {
            me.setState({ "item_error": "Please enter item name" });
            return
        }
        let final_price = Number(me.state.final_price);
        if (final_price === null || final_price === undefined || String(final_price.length) === 0) {
            me.setState({ "final_price_error": "Please enter final price" });
            return
        }
        let profit_amount_ref = Number(me.state.profit_amount_ref);
        if (profit_amount_ref === null || profit_amount_ref === undefined || String(profit_amount_ref.length) === 0) {
            me.setState({ "profit_amount_ref_error": "Please enter profit amount" });
            return
        }
        let cost = Number(me.state.cost);
        if (cost === null || cost === undefined || String(cost.length) === 0) {
            me.setState({ "cost_error": "Please enter cost" });
            return
        }
        let profit_percent = Number(me.state.profit_percent);
        if (profit_percent === null || profit_percent === undefined || String(profit_percent.length) === 0) {
            me.setState({ "profit_percent_error": "Please enter profit %" });
            return
        }
        let handling_charge = Number(me.state.handling_charge);
        if (handling_charge === null || handling_charge === undefined || String(handling_charge.length) === 0) {
            me.setState({ "handling_charge_error": "Please enter handling charge" });
            return
        }
        let profit_amount = Number(me.state.profit_amount);
        if (profit_amount === null || profit_amount === undefined || String(profit_amount.length) === 0) {
            me.setState({ "profit_amount_error": "Please enter profit amount" });
            return
        }
        let total = Number(me.state.total);
        if (total === null || total === undefined || String(total.length) === 0) {
            me.setState({ "total_error": "Please enter total" });
            return
        }
        let gst_percent = Number(me.state.gst_percent);
        if (gst_percent === null || gst_percent === undefined || String(gst_percent.length) === 0) {
            me.setState({ "gst_percent_error": "Please enter GST %" });
            return
        }

        let gst_amount = Number(me.state.gst_amount);
        if (gst_amount === null || gst_amount === undefined || String(gst_amount.length) === 0) {
            me.setState({ "gst_amount_error": "Please enter GST amount" });
            return
        }

        let min_order_qty = Number(me.state.min_order_qty);
        if (min_order_qty === null || min_order_qty === undefined || String(min_order_qty.length) === 0) {
            me.setState({ "min_order_qty_error": "Please enter minimum order quantity" });
            return
        }

        let min_order_value = Number(me.state.min_order_value);
        if (min_order_value === null || min_order_value === undefined || String(min_order_value.length) === 0) {
            me.setState({ "min_order_value_error": "Please enter minimum order value" });
            return
        }

        let min_profit = Number(me.state.min_profit);
        if (min_profit === null || min_profit === undefined || String(min_profit.length) === 0) {
            me.setState({ "min_profit_error": "Please enter minimum profit" });
            return
        }

        let remarks = me.state.remarks;
        if (remarks === null || remarks === undefined || remarks.length === 0) {
            me.setState({ "remarks_error": "Please enter remarks" });
            return
        }

        let postData = {
            item: item,
            profit_amount_ref: profit_amount_ref,
            final_price: final_price,
            cost: cost,
            profit_percent: profit_percent,
            handling_charge: handling_charge,
            profit_amount: profit_amount,
            total: total,
            gst_percent: gst_percent,
            gst_amount: gst_amount,
            min_order_qty: min_order_qty,
            min_order_value: min_order_value,
            min_profit: min_profit,
            remarks: remarks,
        }
        this.props.update(this.state.itemId, postData)

    }

    priceComputation() {
        let me = this;
        let cost = Number(me.state.cost);
        let handling_charge = Number(me.state.handling_charge);
        let profit_percent = Number(me.state.profit_percent);
        let gst_percent = Number(me.state.gst_percent);
        let min_order_qty = Number(me.state.min_order_qty);
        let profit_amount = Math.ceil(cost * Number(profit_percent / 100));
        let profit_amount_ref = Math.ceil(profit_amount * 0.75);
        let total = parseInt(cost + handling_charge + profit_amount);
        let gst_amount = Math.ceil(total * Number(gst_percent / 100));
        let final_price = Math.round(total + gst_amount);
        let min_order_value = parseInt(min_order_qty * final_price);
        let min_profit = parseInt(min_order_qty * profit_amount);

        this.setState({
            "profit_amount": profit_amount,
            "profit_amount_ref": profit_amount_ref,
            "total": total,
            "gst_amount": gst_amount,
            "final_price": final_price,
            "min_order_value": min_order_value,
            "min_profit": min_profit,
        })

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.rateCardReducer.updateProg === true &&
            nextProps.rateCardReducer.updateProg === false
        ) {
            if (nextProps.rateCardReducer.error === null) {
                this.props.history.push("/app/rate")
            }
        } else {
            /* eslint-disable no-unused-vars */
            let error = nextProps.rateCardReducer.error;
            /* eslint-enable no-unused-vars */
        }
    }


    render() {
        let classes = this.props.classes;
        const auth_user = this.props.authReducer.auth_user;
        if (auth_user === null) {
            return null
        }
        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Breadcrumbs aria-label="Breadcrumb" className={classes.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to='/app/rate' >
                            <Typography>Rate Card</Typography>
                        </Link>
                        <Typography color="textPrimary">
                            Edit
                        </Typography>
                    </Breadcrumbs>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Item Name</InputLabel>
                        <OutlinedInput
                            type="text"
                            id="item"
                            label="Item Name"
                            value={this.state.item}
                            className={classes.input_field}
                            required
                            error={this.state.item_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.item_error.length === 0 ? "" : this.state.item_error}
                            onChange={(event) => {
                                this.setState({ "item": event.target.value, "item_error": "" })
                            }}
                            inputProps={{ maxLength: 50 }}
                        />
                        {this.state.item_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.item_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Cost</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="cost"
                            label="Cost"
                            value={this.state.cost}
                            className={classes.input_field}
                            required
                            error={this.state.cost_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.cost_error.length === 0 ? "" : this.state.cost_error}
                            onChange={(event) => {
                                let cost = event.target.value;
                                this.setState({
                                    "cost": cost,
                                    "cost_error": "",
                                }, function () {
                                    this.priceComputation()
                                })
                            }}
                            onMouseLeave={() => {
                                this.setState({
                                    "cost": Number(this.state.cost),
                                })
                            }}
                            inputProps={{ min: 0 }}
                        />
                        {this.state.cost_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.cost_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Handling charges</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="handling_charge"
                            label="Handling charges"
                            value={this.state.handling_charge}
                            className={classes.input_field}
                            required
                            error={this.state.handling_charge_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.handling_charge_error.length === 0 ? "" : this.state.handling_charge_error}
                            onChange={(event) => {
                                let handling_charge = event.target.value;
                                this.setState({
                                    "handling_charge": handling_charge,
                                    "handling_charge_error": "",
                                }, function () {
                                    this.priceComputation()
                                })
                            }}
                            onMouseLeave={() => {
                                this.setState({
                                    "handling_charge": Number(this.state.handling_charge),
                                })
                            }}
                            inputProps={{ min: 0 }}
                        />
                        {this.state.handling_charge_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.handling_charge_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Profit %</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="profit%"
                            label="Profit %"
                            value={this.state.profit_percent}
                            className={classes.input_field}
                            required
                            error={this.state.profit_percent_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.profit_percent_error.length === 0 ? "" : this.state.profit_percent_error}
                            onChange={(event) => {
                                this.setState({
                                    "profit_percent": event.target.value,
                                    "profit_percent_error": "",
                                }, function () {
                                    this.priceComputation()
                                })
                            }}
                            onMouseLeave={() => {
                                this.setState({
                                    "profit_percent": Number(this.state.profit_percent),
                                })
                            }}
                            inputProps={{ min: 0 }}
                        />
                        {this.state.profit_percent_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.profit_percent_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">GST %</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="GST%"
                            label="GST %"
                            value={this.state.gst_percent}
                            className={classes.input_field}
                            required
                            error={this.state.gst_percent_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.gst_percent_error.length === 0 ? "" : this.state.gst_percent_error}
                            onChange={(event) => {
                                this.setState({
                                    "gst_percent": event.target.value,
                                    "gst_percent_error": "",
                                }, function () {
                                    this.priceComputation()
                                })
                            }}
                            onMouseLeave={() => {
                                this.setState({
                                    "gst_percent": Number(this.state.gst_percent),
                                })
                            }}
                            inputProps={{ min: 0 }}
                        />
                        {this.state.gst_percent_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.gst_percent_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Min order qty</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="minOrderQty"
                            label="Min order qty"
                            value={this.state.min_order_qty}
                            className={classes.input_field}
                            required
                            error={this.state.min_order_qty_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.min_order_qty_error.length === 0 ? "" : this.state.min_order_qty_error}
                            onChange={(event) => {
                                this.setState({
                                    "min_order_qty": event.target.value,
                                    "min_order_qty_error": ""
                                }, function () {
                                    this.priceComputation()
                                })
                            }}
                            onMouseLeave={() => {
                                this.setState({
                                    "min_order_qty": Number(this.state.min_order_qty),
                                })
                            }}
                            inputProps={{ min: 0 }}
                        />
                        {this.state.min_order_qty_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.min_order_qty_error}</Typography>}
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Profit Amount</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="profit_amount"
                            label="Profit Amount"
                            value={this.state.profit_amount}
                            className={classes.input_field}
                            required
                            error={this.state.profit_amount_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.profit_amount_error.length === 0 ? "" : this.state.profit_amount_error}
                            // onChange={(event) => {
                            //     this.setState({ "profit_amount": Number(event.target.value), "profit_amount_error": "" })
                            // }}
                            disabled
                        />
                        {this.state.profit_amount_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.profit_amount_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Profit Amount (Ref)</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="profitAmount"
                            label="Profit Amount (Ref)"
                            value={this.state.profit_amount_ref}
                            className={classes.input_field}
                            required
                            error={this.state.profit_amount_ref_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.profit_amount_ref_error.length === 0 ? "" : this.state.profit_amount_ref_error}
                            // onChange={(event) => {
                            //     this.setState({ "profit_amount_ref": Number(event.target.value), "profit_amount_ref_error": "" })
                            // }}
                            disabled
                        />
                        {this.state.profit_amount_ref_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.profit_amount_ref_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Total</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="total"
                            label="Total"
                            value={this.state.total}
                            className={classes.input_field}
                            required
                            error={this.state.total_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.total_error.length === 0 ? "" : this.state.total_error}
                            // onChange={(event) => {
                            //     this.setState({ "total": Number(event.target.value), "total_error": "" })
                            // }}
                            disabled
                        />
                        {this.state.total_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.total_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">GST Amount</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="gstAmount"
                            label="GST Amount"
                            value={this.state.gst_amount}
                            className={classes.input_field}
                            required
                            error={this.state.gst_amount_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.gst_amount_error.length === 0 ? "" : this.state.gst_amount_error}
                            // onChange={(event) => {
                            //     this.setState({ "gst_amount": Number(event.target.value), "gst_amount_error": "" })
                            // }}
                            disabled
                        />
                        {this.state.gst_amount_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.gst_amount_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Final Price after GST</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="finalPrice"
                            label="Final Price after GST"
                            value={this.state.final_price}
                            className={classes.input_field}
                            required
                            error={this.state.final_price_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.final_price_error.length === 0 ? "" : this.state.final_price_error}
                            // onChange={(event) => {
                            //     this.setState({ "final_price": Number(event.target.value), "final_price_error": "" })
                            // }}
                            disabled
                        />
                        {this.state.final_price_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.final_price_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Min order value</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="minOrderValue"
                            label="Min order value"
                            value={this.state.min_order_value}
                            className={classes.input_field}
                            required
                            error={this.state.min_order_value_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.min_order_value_error.length === 0 ? "" : this.state.min_order_value_error}
                            // onChange={(event) => {
                            //     this.setState({ "min_order_value": Number(event.target.value), "min_order_value_error": "" })
                            // }}
                            disabled
                        />
                        {this.state.min_order_value_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.min_order_value_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Min profit</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="minProfit"
                            label="Min profit"
                            value={this.state.min_profit}
                            className={classes.input_field}
                            required
                            error={this.state.min_profit_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.min_profit_error.length === 0 ? "" : this.state.min_profit_error}
                            // onChange={(event) => {
                            //     this.setState({ "min_profit": Number(event.target.value), "min_profit_error": "" })
                            // }}
                            disabled
                        />
                        {this.state.min_profit_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.min_profit_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Remarks</InputLabel>
                        <OutlinedInput
                            type="text"
                            id="remarks"
                            label="Remarks"
                            value={this.state.remarks}
                            className={classes.input_field}
                            required
                            error={this.state.remarks_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.remarks_error.length === 0 ? "" : this.state.remarks_error}
                            onChange={(event) => {
                                this.setState({ "remarks": event.target.value, "remarks_error": "" })
                            }}
                        />
                        {this.state.remarks_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.remarks_error}</Typography>}
                    </FormControl>
                    <CardActions>
                        <Button variant="contained" color="primary" onClick={() => {
                            this.updateItem();
                        }}>
                            Update
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => {
                           this.props.history.push("/app/rate")
                        }}>
                            Cancel
                        </Button>
                    </CardActions>
                </Paper>
            </div>
        );
    }
}


export default compose(
    withStyles(useStyle)
)(connect((state, props) => {
    //State to Prop
    return {
        rateCardReducer: state.rateCardReducer,
        authReducer: state.authReducer,

    };
}, {
    update: RateCardAction.update
})(RateCardEdit))
