import { createStyles} from '@material-ui/core/styles';


export const useStyles =  function(theme){

    const {breakpoints}     =   theme;

    return createStyles({
       
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
      },
      root: {
        display: 'flex',
      },
      typo:{
        position:"absolute",
        right: "12px"
       },
      menuButton: {
        marginRight: theme.spacing(2),
        [breakpoints.up('lg')]: {
          display: 'none',
        },
      },
      toolbar: theme.mixins.toolbar,
    });
};


