import React from "react";
import { connect } from "react-redux";
import {Link as RouterLink} from "react-router-dom"
import { Button, FormControl, InputLabel, OutlinedInput, Paper,Link,
     Typography, Select, MenuItem, Breadcrumbs, CardActions } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import { compose } from "recompose";
import { useStyle } from "./style";
import * as UserAction from "../../../../service/action/user";

class UserAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "name": "",
            "name_error": "",
            "loginId": "",
            "loginId_error": "",
            "password": "",
            "password_error": "",
            "email": "",
            "email_error": "",
            "phone": "",
            "phone_error": "",
            "role": "",
            "role_error": "",
        };
        this.addUser = this.addUser.bind(this);
    }
    addUser() {
        let me = this;
        let name = me.state.name;
        if (name === null || name === undefined || name.length === 0) {
            me.setState({ "name_error": "Please enter name" });
            return
        }
        let email = me.state.email;
        if (email === null || email === undefined || email.length === 0) {
            me.setState({ "email_error": "Please enter email" });
            return
        }
        let loginId = me.state.loginId;
        if (loginId === null || loginId === undefined || loginId.length === 0) {
            me.setState({ "loginId_error": "Please enter login Id" });
            return
        }

        let password = me.state.password;
        if (password === null || password === undefined || password.length === 0) {
            me.setState({ "password_error": "Please enter password" });
            return
        }

        let role = me.state.role;
        if (role === null || role === undefined || role.length === 0) {
            me.setState({ "role_error": "Please select role" });
            return
        }

        const phone = me.state.phone;
        if (phone === null || phone === undefined || phone.length === 0) {
            me.setState({ "phone_error": "Please enter phone no" });
            return
        }

        if (phone.length !== 10) {
            me.setState({ "phone_error": "Please enter valid phone no" });
            return
        }
        let postData = {
            name: name,
            email: email,
            loginId: loginId,
            password: password,
            role: role,
            phone: phone,
        }
        this.props.add(postData)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.userReducer.addProg === true &&
            nextProps.userReducer.addProg === false
        ) {
            if (nextProps.userReducer.error === null) {
                this.props.history.push("/app/user")
            }
        } else {
            /* eslint-disable no-unused-vars */
            let error = nextProps.userReducer.error;
            /* eslint-enable no-unused-vars */
        }
    }

    render() {
        let classes = this.props.classes
        const authUser = this.props.authReducer.auth_user;
        if (authUser === null) {
            return null
        }
        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Breadcrumbs aria-label="Breadcrumb" className={classes.breadcrumbs}>
                        <Link color="inherit" component={RouterLink} to='/app/user' >
                            <Typography>Users</Typography>
                        </Link>
                        <Typography color="textPrimary">
                            Add
                        </Typography>
                    </Breadcrumbs>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Name</InputLabel>
                        <OutlinedInput
                            type="text"
                            id="name"
                            label="Name"
                            value={this.state.name}
                            className={classes.input_field}
                            required
                            error={this.state.name_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.name_error.length === 0 ? "" : this.state.name_error}
                            onChange={(event) => {
                                this.setState({ "name": event.target.value, "name_error": "" })
                            }}
                            inputProps={{ maxLength: 50 }}
                        />
                        {this.state.name_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.name_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
                        <OutlinedInput
                            type="text"
                            id="email"
                            label="Email"
                            value={this.state.email}
                            className={classes.input_field}
                            required
                            error={this.state.email_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.email_error.length === 0 ? "" : this.state.email_error}
                            onChange={(event) => {
                                this.setState({
                                    "email": event.target.value,
                                    "email_error": "",
                                })
                            }}
                            inputProps={{ maxLength: 50 }}
                        />
                        {this.state.email_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.email_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Login ID</InputLabel>
                        <OutlinedInput
                            type="text"
                            id="loginId"
                            label="Login Id"
                            value={this.state.loginId}
                            className={classes.input_field}
                            required
                            error={this.state.loginId_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.loginId_error.length === 0 ? "" : this.state.loginId_error}
                            onChange={(event) => {
                                this.setState({
                                    "loginId": event.target.value,
                                    "loginId_error": "",
                                })
                            }}
                            inputProps={{ maxLength: 50 }}
                        />
                        {this.state.loginId_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.loginId_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            type="password"
                            id="password"
                            label="Password"
                            value={this.state.password}
                            className={classes.input_field}
                            required
                            error={this.state.password_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.password_error.length === 0 ? "" : this.state.password_error}
                            onChange={(event) => {
                                this.setState({
                                    "password": event.target.value,
                                    "password_error": "",
                                })
                            }}
                        />
                        {this.state.password_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.password_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="role"
                            value={this.state.role}
                            onChange={(event) => {
                                this.setState({
                                    "role": event.target.value,
                                    "role_error": "",
                                })
                            }}
                            label="Role"
                            className={classes.input_field}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>

                            {authUser.role === "Admin" && <MenuItem value={"Admin"}>Admin</MenuItem>}
                            {authUser.role === "Admin" && <MenuItem value={"Manager"}>Manager</MenuItem>}
                            <MenuItem value={"User"}>User</MenuItem>
                        </Select>
                        {this.state.role_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.role_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-adornment-password">Phone</InputLabel>
                        <OutlinedInput
                            type="number"
                            id="phone"
                            label="Phone"
                            value={this.state.phone}
                            className={classes.input_field}
                            required
                            error={this.state.phone_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.phone_error.length === 0 ? "" : this.state.phone_error}
                            onChange={(event) => {
                                this.setState({
                                    "phone": event.target.value,
                                    "phone_error": "",
                                })
                            }}
                        />
                        {this.state.phone_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.phone_error}</Typography>}
                    </FormControl>
                    
                    <CardActions>
                        <Button variant="contained" color="primary" onClick={() => {
                            this.addUser();
                        }}>
                            Add
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => {
                           this.props.history.push("/app/user")
                        }}>
                            Cancel
                        </Button>
                    </CardActions>
                </Paper>
            </div>
        );
    }
}


export default compose(
    withStyles(useStyle)
)(connect((state, props) => {
    //State to Prop
    return {
        userReducer: state.userReducer,
        authReducer: state.authReducer,
    };
}, {
    add: UserAction.add
})(UserAdd))
