import { createStyles } from '@material-ui/core/styles';

export const useStyle = function (theme) {

    return createStyles({
        root: {
            width: '100%',
            padding: '15px',
            overflow:"scroll"
        },
        paper:{
            padding:"15px",
            width:"100%",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        },
        input_field:{
            width:"100%",
            marginBottom:"10px"
        },
        breadcrumbs:{
            marginBottom:"10px"
        },
        error:{
            textAlign:"end",
            color:"red"
        }
        
    })
}