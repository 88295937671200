import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';



export default withStyles((theme) => {

  return {
    root: {
      transform: "translate(15px, 15px) scale(1)",
    },
  }
})(InputLabel);