import { createStyles } from '@material-ui/core/styles';

export const useStyle = function (theme) {
    const {palette,breakpoints}     =   theme;

    return createStyles({
        root: {
            width: '100%',
            padding: '15px',
            overflow:"auto",
            [breakpoints.down('md')]:{
                padding:"0px"
            },
        },
        paper:{
            padding:"15px",
            width:"100%",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            [breakpoints.down('md')]:{
                marginBottom:"20%",
            },
        },
        breadcrumbs:{
            marginBottom:"10px"
        },
        input_field:{
            width:"98%",
            marginBottom:"10px",
            [breakpoints.down('md')]:{
                width:"100%",
                marginBottom:"0px"
            },
        },
        formControl:{
            width:"50%",
            marginBottom:"5px",
            [breakpoints.down('md')]:{
                width:"100%",
                marginBottom:"15px"
            },
        },
        error:{
            textAlign:"end",
            color:"red"
        },
        mainhead: {
            background:palette.secondry.main
        },
        tableHeader: {
            // color: "#ffff",
            fontWeight: '600',
            fontSize: '14px'
        },
        table:{
            tableLayout:"fixed"
        },
        commentCard:{
            marginTop:"15px",
            maxHeight:"fit-content"
        },
        commentContent:{
            maxHeight:"250px",
            overflow:"auto"
        },
        commentAction:{
            display:"grid"
        },
        commentInput:{
            marginBottom:"15px"
        },
        warning:{
            backgroundColor:"yellow",
            textAlign:"end",
        },
        bottomNavRoot:{
            height:'10%',
            position:"fixed",
            bottom:0,
            left:0,
            right:0,
            borderTop:"solid 1px "+palette.primary.main,
            [breakpoints.down('md')]:{
                height:'10%',
                zIndex:"10"
            },
        },
        stepper :{
            [breakpoints.down("md")]:{
                backgroundColor:palette.primary.main,
                // color:"#ffff",
            },
        },
        commentList:{
            padding:"0px 16px"
        },
        cancelButton:{
            float:"right"
        },
        updateButton:{
            width: "100%",
            [breakpoints.down("md")]:{
               display:"none"
            },
        },
        tabledata:{
            padding:"6px 10px"
        },
        tableRow:{
            height:"50px"
        },
        
    })
}