import React from "react";
import { connect } from "react-redux";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Paper, TableContainer
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { compose } from "recompose";
import { useStyle } from "./style";
import * as RateCardAction from "../../../../service/action/rateCard"

class RateCardList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'rateList': [],
        };
    }
    componentDidMount() {
        this.props.fetch()
    }

    //pagination code for future use
    /*handleChangePage(event, newPage) {
        this.setState({'page':newPage},function(){
            // this.fetch();
        })
    }
    handleChangeRowsPerPage(event) {
        var me  =   this;
        me.state.filter.page_size =   parseInt(event.target.value,10);
        me.setState({rowsPerPage:parseInt(event.target.value,10)},function(){
            // me.fetch();
        })
        
    }*/
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.rateCardReducer.fetchProg === true &&
            nextProps.rateCardReducer.fetchProg === false
        ) {
            if (nextProps.rateCardReducer.error === null) {
                let data = nextProps.rateCardReducer.rateCard;
                this.setState({ rateList: data, count: data.length });
            }
        } else {
            /* eslint-disable no-unused-vars */
            let error = nextProps.rateCardReducer.error;
            /* eslint-enable no-unused-vars */
        }
    }

    render() {
        let classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Paper elevation={3} className={classes.paper}>
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        Rate Card
                    </Typography>
                    <TableContainer>
                        <Table aria-label="table" className={classes.table} size="small" >
                            <TableHead className={classes.mainhead}>
                                <TableRow  >
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Item</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Final Price after GST</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Profit Amount (Ref)</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Cost</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Profit %</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Handling Charges</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Profit Amount</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Total</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >GST %</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >GST amount</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Min order qty</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Min order value</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Min profit</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Remarks</Typography></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rateList.length === 0 && <TableRow><TableCell colSpan={14} style={{ textAlign: "center" }}><Typography >No Records Found</Typography></TableCell></TableRow>}
                                {this.state.rateList.map((row, i) => (
                                    <TableRow key={i} style={{ cursor: "pointer" }} onClick={() => {
                                        this.props.history.push("/app/rate/" + row._id, row)
                                    }}>
                                        <TableCell align="left" className={classes.tabledata}>{row.item}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.final_price}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.profit_amount_ref}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.cost}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.profit_percent}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.handling_charge}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.profit_amount}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.total}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.gst_percent}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.gst_amount}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.min_order_qty}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.min_order_value}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.min_profit}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.remarks}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            {/* pagination code for future use */}
                            {/* <TableFooter>
                            <TablePagination
                                classes={{ root: classes.paginate }}
                                rowsPerPageOptions={[5,10, 15]}
                                colSpan={14}
                                count={state.count}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationModel}
                            />
                        </TableFooter> */}
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        );
    }
}

export default compose(
    withStyles(useStyle)
)(connect((state, props) => {
    //State to Prop
    return {
        rateCardReducer: state.rateCardReducer,
    };
}, {
    fetch: RateCardAction.fetch
})(RateCardList))
