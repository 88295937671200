import {
    AUTH_END,
    AUTH_START, GET_AUTH_USER_END, GET_AUTH_USER_START,AUTH_LOGOUT
} from "../action/auth";

const initState = {
    auth_user: null,
    error: null,
    authentication: false,
    getAuthProg:false
};

const authReducer = (state = initState, action) => {
    let payload = action.payload;
    switch (action.type) {
        case AUTH_START:
            return { ...state, authentication: true };
        case AUTH_END:
            let _error = null;
            if (payload.error !== undefined) {
                _error = payload.error;
            }
            return { ...state,error: _error, authentication: false };

        case GET_AUTH_USER_START:
            return { ...state, getAuthProg: true };
        case GET_AUTH_USER_END:
            let _user = {};
            let _getError = null;
            if (payload.data !== undefined) {
                _user = payload.data;
            }

            if (payload.error !== undefined) {
                _getError = payload.error;
            }
            return { ...state, auth_user: _user, error: _getError, getAuthProg: false };
            
        case AUTH_LOGOUT:
            return { ...state, auth_user: null };
        default:
            return { ...state };
    }
};
export default authReducer;
