import React from "react";
import { Paper, InputAdornment, IconButton, FormControl, Typography, Button } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import { Visibility, VisibilityOff, Mail } from '@material-ui/icons';
import { connect } from "react-redux";
import { useStyle } from "./style";
import Logo from "../../images/Lmlogo.PNG";
import * as AuthAction from "../../service/action/auth";
import OutlinedInput from "../Application/common/outlinedInput"
import InputLabel from "../Application/common/inputLabel"
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: "",
            loginId_error: "",
            password: "",
            password_error: "",
            showPassword: false
        }
        this.handleSignIn = this.handleSignIn.bind(this);

    }
    handleSignIn() {
        let me = this;
        let loginId = me.state.loginId;
        let password = me.state.password;

        if (loginId === null || loginId === undefined || loginId.length === 0) {
            me.setState({ "loginId_error": "Please enter loginId" });
            return
        }
        if (password === null || password === undefined || password.length === 0) {
            me.setState({ "password_error": "Please enter password" });
            return
        }
        let data = {
            loginId: loginId,
            password: password
        }
        me.props.auth(data)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.authReducer.authentication === true &&
            nextProps.authReducer.authentication === false
        ) {
            if (nextProps.authReducer.error === null) {
                this.props.history.push("/app")
            }
        } else {
            /* eslint-disable no-unused-vars */
            let error = nextProps.authReducer.error;
            /* eslint-enable no-unused-vars */
        }
    }
    render() {
        let classes = this.props.classes;
        return (<div className={classes.main}>
            <Paper className={classes.paper}>
                <img src={Logo} alt="logo" className={classes.logo}></img>
                <Typography variant="body1" className={classes.typo}>{"QPC (" + process.env.REACT_APP_ENV + ")"}</Typography>
                <FormControl variant="outlined" className={classes.input_field}>
                    <InputLabel htmlFor="outlined-adornment-password">Login Id</InputLabel>
                    <OutlinedInput
                        type="text"
                        id="loginId"
                        label="Login Id"
                        value={this.state.loginId}
                        className={classes.input_field}
                        required
                        error={this.state.loginId_error.length !== 0}
                        variant="outlined"
                        helpertext={this.state.loginId_error.length === 0 ? "" : this.state.loginId_error}
                        onChange={(event) => {
                            this.setState({ "loginId": event.target.value, "loginId_error": "" })
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                >
                                    <Mail />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {this.state.loginId_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.loginId_error}</Typography>}
                </FormControl>
                <FormControl variant="outlined" className={classes.input_field}>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        type={this.state.showPassword ? 'text' : 'password'}
                        id="password"
                        label="Password"
                        value={this.state.password}
                        variant="outlined"
                        className={classes.input_field}
                        required
                        error={this.state.password_error.length !== 0}
                        onChange={(event) => {
                            this.setState({ "password": event.target.value, "password_error": "" })
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        this.setState({ "showPassword": !this.state.showPassword })
                                    }}
                                    edge="end"
                                >
                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {this.state.password_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.password_error}</Typography>}
                </FormControl>
                <Button variant="contained" className={classes.button} onClick={() => {
                    this.handleSignIn();
                }}>
                    Sign In
                </Button>
            </Paper>
        </div>)
    }
}
export default connect(
    (state, props) => {
        //State to Prop
        return {
            authReducer: state.authReducer,
        };
    },
    {
        auth: AuthAction.auth,
    }
)(withStyles(useStyle)(Login));