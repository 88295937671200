import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import List from "./List";
import Edit from "./Edit";
import Add from "./Add";
import View from "./View";

class Quotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div>
                    <Switch>
                        <Route path={`${this.props.match.path}/list`} component={List}/>
                        <Route path={`${this.props.match.path}/create`} component={Add}/>
                        <Route path={`${this.props.match.path}/view`} component={View}/>
                        <Route path={`${this.props.match.path}/edit`} component={Edit}/>
                        <Redirect exact path={`${this.props.match.path}`} to={`${this.props.match.path}/list`}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

export default connect(
    (state, props) => {
        //State to Prop
        return {
        };
    },
)(withRouter(Quotes));
