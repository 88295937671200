import React from "react";
import { connect } from "react-redux";
import { Button, FormControl, InputLabel, OutlinedInput, Card,
     Typography, CardActions } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import { compose } from "recompose";
import { useStyle } from "./style";
import * as UserAction from "../../../service/action/user";

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "currentPassword": "",
            "currentPassword_error": "",
            "confirmPassword": "",
            "confirmPassword_error": "",
            "newPassword": "",
            "newPassword_error": "",
        };
        this.changePassword = this.changePassword.bind(this);
    }
    changePassword() {
        let me = this;
        let currentPassword = me.state.currentPassword;
        if (currentPassword === null || currentPassword === undefined || currentPassword.length === 0) {
            me.setState({ "currentPassword_error": "Please enter currentPassword" });
            return
        }
        let newPassword = me.state.newPassword;
        if (newPassword === null || newPassword === undefined || newPassword.length === 0) {
            me.setState({ "newPassword_error": "Please enter newPassword" });
            return
        }
        let confirmPassword = me.state.confirmPassword;
        if (confirmPassword === null || confirmPassword === undefined || confirmPassword.length === 0) {
            me.setState({ "confirmPassword_error": "Please confirm password" });
            return
        }

        if (newPassword !== confirmPassword) {
            me.setState({ "confirmPassword_error": "password did not match ." });
            return
        }

        let postData = {
            currentPassword: currentPassword,
            newPassword: newPassword,
        }
        this.props.changePassword(postData)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.userReducer.changePasswordProg === true &&
            nextProps.userReducer.changePasswordProg === false
        ) {
            if (nextProps.userReducer.error === null) {
                this.props.history.push("/app/quote")
            }
        } else {
            /* eslint-disable no-unused-vars */
            let error = nextProps.userReducer.error;
            /* eslint-enable no-unused-vars */
        }
    }

    render() {
        let classes = this.props.classes
        const authUser = this.props.authReducer.auth_user;
        if (authUser === null) {
            return null
        }
        return (
            <div className={classes.root}>
                <Card className={classes.paper}>
                        <Typography color="textPrimary">
                            Cange Password
                        </Typography>
                    <FormControl variant="outlined" className={classes.input_field}>
                        <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
                        <OutlinedInput
                            type="password"
                            id="currentPassword"
                            label="Current Password"
                            value={this.state.currentPassword}
                            className={classes.input_field}
                            required
                            error={this.state.currentPassword_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.currentPassword_error.length === 0 ? "" : this.state.currentPassword_error}
                            onChange={(event) => {
                                this.setState({ "currentPassword": event.target.value, "currentPassword_error": "" })
                            }}
                            inputProps={{ maxLength: 50 }}
                        />
                        {this.state.currentPassword_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.currentPassword_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.input_field}>
                        <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                        <OutlinedInput
                            type="password"
                            id="newPassword"
                            label="New Password"
                            value={this.state.newPassword}
                            className={classes.input_field}
                            required
                            error={this.state.newPassword_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.newPassword_error.length === 0 ? "" : this.state.newPassword_error}
                            onChange={(event) => {
                                this.setState({
                                    "newPassword": event.target.value,
                                    "newPassword_error": "",
                                })
                            }}
                            inputProps={{ maxLength: 50 }}
                        />
                        {this.state.newPassword_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.newPassword_error}</Typography>}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.input_field}>
                        <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            type="password"
                            id="confirmPassword"
                            label="Confirm Password"
                            value={this.state.confirmPassword}
                            className={classes.input_field}
                            required
                            error={this.state.confirmPassword_error.length !== 0}
                            variant="outlined"
                            helpertext={this.state.confirmPassword_error.length === 0 ? "" : this.state.confirmPassword_error}
                            onChange={(event) => {
                                this.setState({
                                    "confirmPassword": event.target.value,
                                    "confirmPassword_error": "",
                                })
                            }}
                            inputProps={{ maxLength: 50 }}
                        />
                        {this.state.confirmPassword_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.confirmPassword_error}</Typography>}
                    </FormControl>
                    <CardActions>
                        <Button variant="contained" color="primary" onClick={() => {
                            this.changePassword();
                        }}>
                            Change
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => {
                           this.props.history.push("/app/quote")
                        }}>
                            Cancel
                        </Button>
                    </CardActions>
                </Card>
            </div>
        );
    }
}


export default compose(
    withStyles(useStyle)
)(connect((state, props) => {
    //State to Prop
    return {
        userReducer: state.userReducer,
        authReducer: state.authReducer,
    };
}, {
    changePassword: UserAction.changePassword
})(ChangePassword))
