import React,{Fragment} from "react";
import { List, ListItem, ListItemIcon, ListItemText, Divider, Drawer, Typography, Hidden, withWidth, CssBaseline } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { useStyle } from "./style";
import { ListAltOutlined, SupervisorAccountOutlined, PowerSettingsNewOutlined, Assignment, Lock } from '@material-ui/icons';
import * as authAction from "../../../../service/action/auth"
import ModalComponent from "../modal"
class ApplicationDrawer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedItem: 1,
            saleUser: false,
            manager: false,
            logoutModal:false
        }
        this.hideDrawer = this.hideDrawer.bind(this);
    }
    componentDidMount() {
        if (this.props.authReducer.auth_user !== undefined && this.props.authReducer.auth_user !== null) {
            const authUser = this.props.authReducer.auth_user;
            if (authUser.role === "User") {
                this.setState({ saleUser: true })
            } else if (authUser.role === "Manager") {
                this.setState({ manager: true })
            }
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.authReducer.getAuthProg === true && nextProps.authReducer.getAuthProg === false) {
            if (nextProps.authReducer.auth_user !== undefined && nextProps.authReducer.auth_user !== null) {
                const authUser = nextProps.authReducer.auth_user;
                if (authUser.role === "User") {
                    this.setState({ saleUser: true })
                } else if (authUser.role === "Manager") {
                    this.setState({ manager: true })
                }
            }
        }
    }

    hideDrawer() {
        var width = this.props.width;
        if (width === 'xs' || width === 'sm' || width === 'md') {
            this.props.handleDrawer()
            return;
        }
    }
    render() {
        const classes = this.props.classes;
        const drawer = (
            <div style={{ fontWeight: 600 }}>
                <div className={classes.toolbar}>
                    <Typography variant="h6" className={classes.typo}>Quote Pricing Calculator</Typography>
                </div>
                <Divider />
                <List>
                    {!this.state.saleUser && !this.state.manager && <ListItem button selected={this.state.selectedItem === 0} onClick={() => {
                        this.setState({ selectedItem: 0 })
                        this.props.history.push("/app/rate/list");
                        this.hideDrawer();
                    }} classes={{ selected: classes.active }}>
                        <ListItemIcon><ListAltOutlined /> </ListItemIcon>
                        <ListItemText primary="Rate Card" />
                    </ListItem>}
                    <ListItem button selected={this.state.selectedItem === 1} onClick={() => {
                        this.setState({ selectedItem: 1 })
                        this.props.history.push("/app/quote/list");
                        this.hideDrawer();
                    }} classes={{ selected: classes.active }}>
                        <ListItemIcon><Assignment /> </ListItemIcon>
                        <ListItemText primary="Quotes" />
                    </ListItem>
                    {!this.state.saleUser && <ListItem button selected={this.state.selectedItem === 2} onClick={() => {
                        this.setState({ selectedItem: 2 })
                        this.props.history.push("/app/user/list");
                        this.hideDrawer();
                    }} classes={{ selected: classes.active }}>
                        <ListItemIcon><SupervisorAccountOutlined /> </ListItemIcon>
                        <ListItemText primary="Users" />
                    </ListItem>}
                    <ListItem button selected={this.state.selectedItem === 3} onClick={() => {
                        this.setState({ selectedItem: 3 })
                        this.props.history.push("/app/change/password");
                        this.hideDrawer();
                    }} classes={{ selected: classes.active }}>
                        <ListItemIcon><Lock /> </ListItemIcon>
                        <ListItemText primary="Change Password" />
                    </ListItem>
                    <ListItem button onClick={() => {
                        
                        this.setState({"logoutModal":true})
                    }}>
                        <ListItemIcon >
                            <PowerSettingsNewOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>

            </div>
        );
        return (
            <Fragment>
                <CssBaseline />
                <div className={classes.root}>
                    <nav className={classes.drawer} aria-label="Mailbox folders">
                        <Hidden lgUp implementation="css">
                            <Drawer
                                variant="temporary"
                                open={this.props.showDrawer}
                                onClose={() => {
                                    this.props.handleDrawer()
                                }}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                        <Hidden mdDown implementation="css">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                variant="permanent"
                                open
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                    </nav>
                </div>
                <ModalComponent 
                    open={this.state.logoutModal} 
                    title="Logout"
                    buttonText="Logout"
                    body="Are you sure want to logout."
                    onClose={()=>{this.hideDrawer();this.setState({logoutModal:false})}}
                    onSubmit={()=>{this.props.logout()}}
                />
            </Fragment>
        );
    }
}
// export default withStyles(useStyle)(ApplicationDrawer);
// export default compose(withRouter,withStyles(useStyle))(ApplicationDrawer)
export default compose(
    withRouter,
    withStyles(useStyle),
    withWidth()
)(connect((state, props) => {
    //State to Prop
    return {
        authReducer: state.authReducer,
    };
}, {
    logout: authAction.logout
})(ApplicationDrawer))