import axios from "axios";
import SnackBarAlertAction, { SnackBarAlertType } from "./snackBarAction";

const url = process.env.REACT_APP_URL;

export const AUTH_START = "AUTH_START";
export const AUTH_END = "AUTH_END";

export const GET_AUTH_USER_START = "GET_AUTH_USER_START";
export const GET_AUTH_USER_END = "GET_AUTH_USER_END";

export const AUTH_LOGOUT = "AUTH_LOGOUT";

export function auth(data) {
    return (dispatch) => {
        dispatch({
            type: AUTH_START,
        });
        axios.post(url + "/auth", data).then(function (response) {
            const response_data = response.data;
            if (response.data !== undefined) {
                window.sessionStorage.setItem("authToken", response_data.data.token);
                dispatch({
                    type: AUTH_END,
                    payload: {},
                });
            } else {
                dispatch({
                    type: AUTH_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!", SnackBarAlertType.ERROR));
            }
        }).catch(function (error) {
            if (
                error.response !== undefined &&
                error.response.data !== undefined &&
                error.response.data.message !== undefined
            ) {
                dispatch({
                    type: AUTH_END,
                    payload: {
                        error: error.response.data.message,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert(error.response.data.message, SnackBarAlertType.ERROR));

            } else {
                dispatch({
                    type: AUTH_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!", SnackBarAlertType.ERROR));
            }
        });
    }
}

export function getAuthUser() {
    const authToken = window.sessionStorage.getItem("authToken", null);
    return (dispatch) => {
        dispatch({
            type: GET_AUTH_USER_START,
        });
        axios.get(url + "/auth/user", {
            headers: {
                Authorization: "Bearer " + authToken,
            },
        }).then(function (response) {
            const response_data = response.data;
            if (response.data !== undefined) {
                dispatch({
                    type: GET_AUTH_USER_END,
                    payload: {
                        data: response_data.data,
                    },
                });
            } else {
                dispatch({
                    type: GET_AUTH_USER_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
            }
        }).catch(function (error) {
            if (
                error.response !== undefined &&
                error.response.data !== undefined &&
                error.response.data.message !== undefined
            ) {
                dispatch({
                    type: GET_AUTH_USER_END,
                    payload: {
                        error: error.response.data.message,
                    },
                });

            } else {
                dispatch({
                    type: GET_AUTH_USER_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
            }
        });
    }
}

export function logout() {
    return (dispatch) => {
        window.sessionStorage.removeItem("authToken");
        dispatch({
            type: AUTH_LOGOUT,
        });
    }
}