import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withStyles } from '@material-ui/core/styles';



export default withStyles((theme) => {
  return {
    root: {
      '& .MuiOutlinedInput-input': {
        padding: "12px",
        fontSize: "17px"
      },
    },
  }
})(OutlinedInput);