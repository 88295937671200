import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
    Button, Table, TableBody, TableCell, TableHead, TableRow, FormControl,
    Paper, Typography, Grid, Card, Breadcrumbs, Link,
    List, ListItem, ListItemText, CardContent, Divider, CardActions, CardActionArea, Hidden, MobileStepper
} from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import { compose } from "recompose";
import * as moment from "moment"
import { useStyle } from "./style";
import * as QuoteAction from "../../../../service/action/quotes";
import * as RateCardAction from "../../../../service/action/rateCard";
import ModalComponent from "../../common/modal";
import InputLabel from "../../common/inputLabel";
import OutlinedInput from "../../common/outlinedInput";
const nonEditableFields = ["Tara Book & sw, per level", "Assessment (pre)", "Assessment (mid)", "Assessment (end)"];
const editableFields = ["Alexa Echodot device", "ToolBox", "Activity kit (1 activity)", "In person workshop", "Mentorship calls", "Video conference session"];
const steps = ['School Info', 'Ideal & recommended', 'Proposed Quote', 'Comments'];

class QuoteUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "_id": null,
            "quote_id": "",
            "baseline_quote": [],
            "proposed_quote": [],
            "school_name": "",
            "school_name_error": "",
            "school_location": "",
            "school_location_error": "",
            "school_contact_name": "",
            "school_contact_name_error": "",
            "school_contact_phone": "",
            "school_contact_phone_error": "",
            "no_of_students": "",
            "no_of_students_error": "",
            "no_of_teachers": "",
            "no_of_teachers_error": "",
            "total_learners": "",
            "total_classes": "",
            "total_classes_error": "",
            "discount_percent_on_total_order": 0,
            "total_order_after_discount": "",
            "final_price_per_student": "",
            "total_discount_value": "",
            "total_amount_per_student": "",
            "gst_amount": "",
            "price_before_tax": "",
            "comments": [],
            "commentInput": "",
            "profit_before_discount": "",
            "profit_after_discount": "",
            "userRole": "",
            "discount_approved_by": "",
            "discount_approved_on": null,
            "discount_percent_on_total_order_error": "",
            "discount_percent_on_total_order_warning": "",
            "navigation": "schoolInfo",
            'activeStep': 0,
            "confirmModal":false,
            "isEdit":false
        };
        this.updateQuote = this.updateQuote.bind(this);
        this.initialBaselineQuoteComputation = this.initialBaselineQuoteComputation.bind(this);
        this.discountComputation = this.discountComputation.bind(this);
        this.handleProposedQuoteInput = this.handleProposedQuoteInput.bind(this);
        this.updateProposedQuoteComputation = this.updateProposedQuoteComputation.bind(this);
        this.handleBottomNav = this.handleBottomNav.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentDidMount() {
        const me = this;

        let data = me.props.location.state;
        console.log("location.state", data)
        const userRole = me.props.authReducer.auth_user.role;
        if (data !== undefined) {
            me.setState({
                "_id": data._id,
                "quote_id": data.quote_id,
                "baseline_quote": data.baseline_quote,
                "proposed_quote": data.proposed_quote,
                "school_name": data.school_name,
                "school_location": data.school_location,
                "school_contact_name": data.school_contact_name,
                "school_contact_phone": data.school_contact_phone,
                "no_of_students": data.no_of_students,
                "no_of_teachers": data.no_of_teachers,
                "total_learners": data.total_learners,
                "total_classes": data.total_classes,
                "discount_percent_on_total_order": data.discount_percent_on_total_order,
                "total_order_after_discount": data.total_order_after_discount,
                "final_price_per_student": data.final_price_per_student,
                "total_discount_value": data.total_discount_value,
                "total_amount_per_student": data.total_amount_per_student,
                "gst_amount": data.gst_amount,
                "price_before_tax": data.price_before_tax,
                "comments": data.comments,
                "profit_before_discount": data.profit_before_discount,
                "profit_after_discount": data.profit_after_discount,
                "userRole": userRole,
                "discount_approved_by": data.discount_approved_by,
                "discount_approved_on": data.discount_approved_on,
            })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.quoteReducer.updateProg === true &&
            nextProps.quoteReducer.updateProg === false
        ) {
            if (nextProps.quoteReducer.error === null) {
                this.props.history.push("/app/quote")
            }
        } else {
            /* eslint-disable no-unused-vars */
            let error = nextProps.quoteReducer.error;
            /* eslint-enable no-unused-vars */
        }
    }

    // initially calculate baseline quote
    initialBaselineQuoteComputation() {
        const me = this;
        let no_of_students = Number(me.state.no_of_students);
        let no_of_teachers = Number(me.state.no_of_teachers);
        let total_learners = no_of_students + no_of_teachers;
        let total_classes = Number(me.state.total_classes);
        let baseline_quote_copy = [...me.state.baseline_quote];
        let inPersonWorkshop = Number(Math.ceil(total_learners / 250) * 3) < 3 ? 3 : Number(Math.ceil(total_learners / 250) * 3);
        let videoConfSess = Number(Math.ceil(no_of_teachers / 25) * 3) < 3 ? 3 : Number(Math.ceil(no_of_teachers / 25) * 3);
        let total_order_value = 0
        let price_per_student = 0
        for (let i = 0; i < baseline_quote_copy.length; i++) {
            const current_baseline_quote = { ...baseline_quote_copy[i] };
            if (nonEditableFields.includes(current_baseline_quote.name)) {
                current_baseline_quote.no_of_unit = total_learners;
                current_baseline_quote.final_price = parseFloat(total_learners * current_baseline_quote.single_unit_price);
            }
            if (current_baseline_quote.name === "ToolBox") {
                current_baseline_quote.no_of_unit = total_classes;
                current_baseline_quote.final_price = parseFloat(total_classes * current_baseline_quote.single_unit_price);
            }
            if (current_baseline_quote.name === "Mentorship calls") {
                current_baseline_quote.no_of_unit = Number(no_of_teachers * 3);
                current_baseline_quote.final_price = parseFloat(Number(no_of_teachers * 3) * current_baseline_quote.single_unit_price);
            }
            if (current_baseline_quote.name === "Alexa Echodot device") {
                current_baseline_quote.no_of_unit = Math.ceil(total_learners / 250);
                current_baseline_quote.final_price = parseFloat(Math.ceil(total_learners / 250) * current_baseline_quote.single_unit_price);
            }
            if (current_baseline_quote.name === "In person workshop") {
                current_baseline_quote.no_of_unit = inPersonWorkshop
                current_baseline_quote.final_price = parseFloat(inPersonWorkshop * current_baseline_quote.single_unit_price);
            }
            if (current_baseline_quote.name === "Video conference session") {
                current_baseline_quote.no_of_unit = videoConfSess
                current_baseline_quote.final_price = parseFloat(videoConfSess * current_baseline_quote.single_unit_price);
            }
            if (current_baseline_quote.name !== "Total order value" && current_baseline_quote.name !== "Price per student (inclusive of all taxes)") {
                total_order_value += current_baseline_quote.final_price;
            }
            baseline_quote_copy[i] = current_baseline_quote;
        }
        price_per_student = Math.round(total_order_value / total_learners);

        for (let i = 0; i < baseline_quote_copy.length; i++) {
            const current_baseline_quote = { ...baseline_quote_copy[i] };

            if (current_baseline_quote.name === "Total order value") {
                current_baseline_quote.final_price = total_order_value
            }
            if (current_baseline_quote.name === "Price per student (inclusive of all taxes)") {
                current_baseline_quote.final_price = price_per_student
            }
            baseline_quote_copy[i] = current_baseline_quote;

        }
        me.setState({
            baseline_quote: baseline_quote_copy
        })
    }

    // update noneditable fields when user changes the main inputs like no of students , teachers & classes
    updateProposedQuoteComputation() {
        const me = this;
        let no_of_students = Number(me.state.no_of_students);
        let no_of_teachers = Number(me.state.no_of_teachers);
        let total_learners = no_of_students + no_of_teachers;
        let proposed_quote_copy = [...me.state.proposed_quote];
        let total_order_value = 0;
        let price_per_student = 0;
        let profit_before_discount = 0;

        for (let i = 0; i < proposed_quote_copy.length; i++) {
            const current_proposed_quote = { ...proposed_quote_copy[i] };
            if (nonEditableFields.includes(current_proposed_quote.name)) {
                current_proposed_quote.no_of_unit = total_learners;
                current_proposed_quote.final_price = parseFloat(total_learners * current_proposed_quote.single_unit_price);
            }
            if (current_proposed_quote.name !== "Total order value" && current_proposed_quote.name !== "Price per student (inclusive of all taxes)") {
                total_order_value += current_proposed_quote.final_price;
                profit_before_discount += Math.ceil(current_proposed_quote.no_of_unit * current_proposed_quote.profit_amount_ref);

            }
            proposed_quote_copy[i] = current_proposed_quote;
        }
        price_per_student = Math.round(total_order_value / total_learners);
        for (let i = 0; i < proposed_quote_copy.length; i++) {
            const current_proposed_quote = { ...proposed_quote_copy[i] };

            if (current_proposed_quote.name === "Total order value") {
                current_proposed_quote.final_price = total_order_value
            }
            if (current_proposed_quote.name === "Price per student (inclusive of all taxes)") {
                current_proposed_quote.final_price = price_per_student
            }
            proposed_quote_copy[i] = current_proposed_quote;

        }
        me.setState({
            proposed_quote: proposed_quote_copy,
            total_learners: total_learners,
            profit_before_discount: profit_before_discount

        }, function () {
            this.discountComputation();
        })
    }

    //handle the editable input in proposed quote
    handleProposedQuoteInput(selectedItem) {
        const me = this;
        let proposed_quote = [...this.state.proposed_quote];
        let total_order_value = 0;
        let price_per_student = 0;
        let profit_before_discount = 0;

        for (let i = 0; i < proposed_quote.length; i++) {
            const current_proposed_quote = { ...proposed_quote[i] };
            if (current_proposed_quote.name === selectedItem.name) {
                current_proposed_quote.no_of_unit = Number(current_proposed_quote.no_of_unit)
                current_proposed_quote.final_price = Math.ceil(current_proposed_quote.no_of_unit * current_proposed_quote.single_unit_price)
            }
            if (current_proposed_quote.name !== "Total order value" && current_proposed_quote.name !== "Price per student (inclusive of all taxes)") {
                total_order_value += current_proposed_quote.final_price;
                profit_before_discount += Math.ceil(current_proposed_quote.no_of_unit * current_proposed_quote.profit_amount_ref);

            }
            proposed_quote[i] = current_proposed_quote;
        }
        price_per_student = Math.round(total_order_value / me.state.total_learners);
        for (let i = 0; i < proposed_quote.length; i++) {
            const current_proposed_quote = { ...proposed_quote[i] };

            if (current_proposed_quote.name === "Total order value") {
                current_proposed_quote.final_price = total_order_value
            }
            if (current_proposed_quote.name === "Price per student (inclusive of all taxes)") {
                current_proposed_quote.final_price = price_per_student
            }
            proposed_quote[i] = current_proposed_quote;
        }
        me.setState({
            "proposed_quote": proposed_quote,
            "profit_before_discount": profit_before_discount

        }, function () {
            me.discountComputation();
        })
    }

    //for computations of discount dependent fields
    discountComputation() {
        const me = this;
        const auth_user = me.props.authReducer.auth_user;
        const discount_percent_on_total_order = Number(me.state.discount_percent_on_total_order);

        if (auth_user.role === "Manager" && discount_percent_on_total_order > 8.5) {
            me.setState({ "discount_percent_on_total_order_error": "Max discount will be 8.5" });
            return
        }
        if (auth_user.role === "Admin" && discount_percent_on_total_order > 37) {
            me.setState({ "discount_percent_on_total_order_warning": "Discount exceeded 37%" });
            // return
        }
        if (auth_user.role === "Admin" && discount_percent_on_total_order > 80) {
            me.setState({ "discount_percent_on_total_order_error": "Max discount will be 80" });
            return
        }
        const total_order_value = this.state.proposed_quote[this.state.proposed_quote.findIndex(item => item.name === 'Total order value')].final_price;
        const total_discount_value = Math.ceil(parseFloat(discount_percent_on_total_order * total_order_value) / 100);
        const total_order_after_discount = total_order_value - total_discount_value;
        const final_price_per_student = Math.ceil(total_order_after_discount / Number(me.state.total_learners));
        const price_before_tax = Math.ceil(final_price_per_student / 1.18);
        const gst_amount = final_price_per_student - price_before_tax;
        const total_amount_per_student = price_before_tax + gst_amount;
        const profit_after_discount = me.state.profit_before_discount - total_discount_value;

        me.setState({
            "total_discount_value": total_discount_value,
            "total_order_after_discount": total_order_after_discount,
            "final_price_per_student": final_price_per_student,
            "price_before_tax": price_before_tax,
            "gst_amount": gst_amount,
            "total_amount_per_student": total_amount_per_student,
            "profit_after_discount": profit_after_discount

        })
    }

    updateQuote() {
        const me = this;

        const school_name = me.state.school_name;
        if (school_name === null || school_name === undefined || school_name.length === 0) {
            me.setState({ "school_name_error": "Please enter school name" });
            return
        }

        const school_location = me.state.school_location;
        if (school_location === null || school_location === undefined || school_location.length === 0) {
            me.setState({ "school_location_error": "Please enter school location" });
            return
        }

        const school_contact_name = me.state.school_contact_name;
        if (school_contact_name === null || school_contact_name === undefined || school_contact_name.length === 0) {
            me.setState({ "school_contact_name_error": "Please enter school contact name" });
            return
        }


        const school_contact_phone = me.state.school_contact_phone;
        if (school_contact_phone === null || school_contact_phone === undefined || school_contact_phone.length === 0) {
            me.setState({ "school_contact_phone_error": "Please enter school contact phone" });
            return
        }

        if (school_contact_phone.length !== 10) {
            me.setState({ "school_contact_phone_error": "Please enter valid school contact phone" });
            return
        }

        const no_of_students = Number(me.state.no_of_students);
        if (no_of_students === null || no_of_students === undefined) {
            me.setState({ "no_of_students_error": "Please enter no of students" });
            return
        }

        if (no_of_students <= 0) {
            me.setState({ "no_of_students_error": "Students should be greater than 0" });
            return
        }

        const no_of_teachers = Number(me.state.no_of_teachers);
        if (no_of_teachers === null || no_of_teachers === undefined) {
            me.setState({ "no_of_teachers_error": "Please enter no of teacher" });
            return
        }

        if (no_of_teachers <= 0) {
            me.setState({ "no_of_teachers_error": "Teachers should be greater than 0" });
            return
        }

        const total_learners = Number(me.state.total_learners);

        const total_classes = Number(me.state.total_classes);
        if (total_classes === null || total_classes === undefined) {
            me.setState({ "total_classes_error": "Please enter total classes" });
            return
        }

        if (total_classes <= 0) {
            me.setState({ "total_classes_error": "Classes should be greater than 0" });
            return
        }

        const proposed_quote = me.state.proposed_quote;
        const baseline_quote = me.state.baseline_quote;
        let discount_approved_by = me.state.discount_approved_by;
        const last_updated_by = me.props.authReducer.auth_user.name;
        const discount_percent_on_total_order = Number(me.state.discount_percent_on_total_order);
        if (discount_percent_on_total_order !== 0) {
            discount_approved_by = last_updated_by
        }
        const total_order_after_discount = me.state.total_order_after_discount;
        const final_price_per_student = me.state.final_price_per_student;
        const total_discount_value = me.state.total_discount_value;
        const total_amount_per_student = me.state.total_amount_per_student;
        const gst_amount = me.state.gst_amount;
        const price_before_tax = me.state.price_before_tax;
        const comments = me.state.comments;
        const profit_before_discount = Number(me.state.profit_before_discount);
        const profit_after_discount = Number(me.state.profit_after_discount);
        const discount_approved_on = me.state.discount_approved_on;

        const postData = {
            "school_name": school_name,
            "school_location": school_location,
            "school_contact_name": school_contact_name,
            "school_contact_phone": school_contact_phone,
            "no_of_students": no_of_students,
            "no_of_teachers": no_of_teachers,
            "total_learners": total_learners,
            "total_classes": total_classes,
            "proposed_quote": proposed_quote,
            "baseline_quote": baseline_quote,
            "discount_percent_on_total_order": discount_percent_on_total_order,
            "total_order_after_discount": total_order_after_discount,
            "final_price_per_student": final_price_per_student,
            "total_discount_value": total_discount_value,
            "total_amount_per_student": total_amount_per_student,
            "gst_amount": gst_amount,
            "price_before_tax": price_before_tax,
            "comments": comments,
            "profit_before_discount": profit_before_discount,
            "profit_after_discount": profit_after_discount,
            "discount_approved_by": discount_approved_by,
            "discount_approved_on": discount_approved_on,
            "last_updated_by": last_updated_by,

        }
        this.props.update(me.state._id, postData)
    }

    handleBottomNav(val) {
        this.setState({ "navigation": val })
    }

    handleNext() {
        const me = this;
        if (me.state.activeStep === 0) {
            const school_name = me.state.school_name;
            if (school_name === null || school_name === undefined || school_name.length === 0) {
                me.setState({ "school_name_error": "Please enter school name" });
                return
            }

            const school_location = me.state.school_location;
            if (school_location === null || school_location === undefined || school_location.length === 0) {
                me.setState({ "school_location_error": "Please enter school location" });
                return
            }

            const school_contact_name = me.state.school_contact_name;
            if (school_contact_name === null || school_contact_name === undefined || school_contact_name.length === 0) {
                me.setState({ "school_contact_name_error": "Please enter school contact name" });
                return
            }

            const school_contact_phone = me.state.school_contact_phone;
            if (school_contact_phone === null || school_contact_phone === undefined || school_contact_phone.length === 0) {
                me.setState({ "school_contact_phone_error": "Please enter school contact phone" });
                return
            }
            if (school_contact_phone.length !== 10) {
                me.setState({ "school_contact_phone_error": "Please enter valid school contact phone" });
                return
            }

            const no_of_students = Number(me.state.no_of_students);
            if (no_of_students === null || no_of_students === undefined) {
                me.setState({ "no_of_students_error": "Please enter no of students" });
                return
            }
            if (no_of_students <= 0) {
                me.setState({ "no_of_students_error": "Students should be greater than 0" });
                return
            }

            const no_of_teachers = Number(me.state.no_of_teachers);
            if (no_of_teachers === null || no_of_teachers === undefined) {
                me.setState({ "no_of_teachers_error": "Please enter no of teacher" });
                return
            }

            if (no_of_teachers <= 0) {
                me.setState({ "no_of_teachers_error": "Teachers should be greater than 0" });
                return
            }

            const total_classes = Number(me.state.total_classes);
            if (total_classes === null || total_classes === undefined) {
                me.setState({ "total_classes_error": "Please enter total classes" });
                return
            }

            if (total_classes <= 0) {
                me.setState({ "total_classes_error": "Classes should be greater than 0" });
                return
            }
        }
        if (this.state.activeStep === steps.length - 1) {
            this.updateQuote();
        } else {
            this.setState({ 'activeStep': this.state.activeStep + 1 })
        }
    }
    handleBack() {
        this.setState({ 'activeStep': this.state.activeStep - 1 })
    }

    render() {
        let classes = this.props.classes
        const auth_user = this.props.authReducer.auth_user;
        if (auth_user === null) {
            return null
        }
        const schoolInfo = (
            <React.Fragment>
                <Typography variant="h6">School Info</Typography>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-adornment-password">School Name</InputLabel>
                    <OutlinedInput
                        type="text"
                        id="schoolName"
                        label="School Name"
                        value={this.state.school_name}
                        className={classes.input_field}
                        required
                        error={this.state.school_name_error.length !== 0}
                        variant="outlined"
                        helpertext={this.state.school_name_error.length === 0 ? "" : this.state.school_name_error}
                        onChange={(event) => {
                            this.setState({ "school_name": event.target.value, "school_name_error": "" , "isEdit":true})
                        }}
                        inputProps={{ maxLength: 50 }}

                    />
                    {this.state.school_name_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.school_name_error}</Typography>}
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-adornment-password">School Location</InputLabel>
                    <OutlinedInput
                        type="text"
                        id="schoolLocation"
                        label="School Location"
                        value={this.state.school_location}
                        className={classes.input_field}
                        required
                        error={this.state.school_location_error.length !== 0}
                        variant="outlined"
                        helpertext={this.state.school_location_error.length === 0 ? "" : this.state.school_location_error}
                        onChange={(event) => {
                            this.setState({ "school_location": event.target.value, "school_location_error": "" , "isEdit":true})
                        }}
                        inputProps={{ maxLength: 50 }}

                    />
                    {this.state.school_location_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.school_location_error}</Typography>}
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-adornment-password">School Contact Name</InputLabel>
                    <OutlinedInput
                        type="text"
                        id="schoolContactName"
                        label="School Contact Name"
                        value={this.state.school_contact_name}
                        className={classes.input_field}
                        required
                        error={this.state.school_contact_name_error.length !== 0}
                        variant="outlined"
                        helpertext={this.state.school_contact_name_error.length === 0 ? "" : this.state.school_contact_name_error}
                        onChange={(event) => {
                            this.setState({ "school_contact_name": event.target.value, "school_contact_name_error": "", "isEdit":true })
                        }}
                        inputProps={{ maxLength: 50 }}

                    />
                    {this.state.school_contact_name_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.school_contact_name_error}</Typography>}
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-adornment-password">School Contact Phone</InputLabel>
                    <OutlinedInput
                        type="number"
                        id="schoolContactPhone"
                        label="School Contact Phone"
                        value={this.state.school_contact_phone}
                        className={classes.input_field}
                        required
                        error={this.state.school_contact_phone_error.length !== 0}
                        variant="outlined"
                        helpertext={this.state.school_contact_phone_error.length === 0 ? "" : this.state.school_contact_phone_error}
                        onChange={(event) => {
                            this.setState({ "school_contact_phone": event.target.value, "school_contact_phone_error": "", "isEdit":true })
                        }}
                        inputProps={{ maxLength: 10 }}

                    />
                    {this.state.school_contact_phone_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.school_contact_phone_error}</Typography>}
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-adornment-password">Number of Students</InputLabel>
                    <OutlinedInput
                        type="number"
                        id="noOfStudents"
                        label="Number of Students"
                        value={this.state.no_of_students}
                        className={classes.input_field}
                        required
                        error={this.state.no_of_students_error.length !== 0}
                        variant="outlined"
                        helpertext={this.state.no_of_students_error.length === 0 ? "" : this.state.no_of_students_error}
                        onChange={(event) => {
                            this.setState({
                                "no_of_students": event.target.value,
                                "no_of_students_error": "",
                                "isEdit":true
                            }, function () {
                                this.updateProposedQuoteComputation();
                                this.initialBaselineQuoteComputation();
                            })
                        }}
                        inputProps={{ min: 0 }}
                        onMouseLeave={() => {
                            this.setState({
                                "no_of_students": Number(this.state.no_of_students),
                            })
                        }}

                    />
                    {this.state.no_of_students_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.no_of_students_error}</Typography>}
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-adornment-password">Number of Teachers</InputLabel>
                    <OutlinedInput
                        type="number"
                        id="noOfTeachers"
                        label="Number of Teachers"
                        value={this.state.no_of_teachers}
                        className={classes.input_field}
                        required
                        error={this.state.no_of_teachers_error.length !== 0}
                        variant="outlined"
                        helpertext={this.state.no_of_teachers_error.length === 0 ? "" : this.state.no_of_teachers_error}
                        onChange={(event) => {
                            this.setState({
                                "no_of_teachers": event.target.value,
                                "no_of_teachers_error": "",
                                "isEdit":true
                            }, function () {
                                this.updateProposedQuoteComputation();
                                this.initialBaselineQuoteComputation();
                            })
                        }}
                        onMouseLeave={() => {
                            this.setState({
                                "no_of_teachers": Number(this.state.no_of_teachers),
                            })
                        }}
                        inputProps={{ min: 0 }}

                    />
                    {this.state.no_of_teachers_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.no_of_teachers_error}</Typography>}
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-adornment-password">Total Learners</InputLabel>
                    <OutlinedInput
                        type="number"
                        id="totalLearners"
                        label="Total Learners"
                        value={this.state.total_learners}
                        className={classes.input_field}
                        variant="outlined"
                        disabled
                    />
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-adornment-password">Total Classes</InputLabel>
                    <OutlinedInput
                        type="number"
                        id="totalClasses"
                        label="Total Classes"
                        value={this.state.total_classes}
                        className={classes.input_field}
                        required
                        error={this.state.total_classes_error.length !== 0}
                        variant="outlined"
                        helpertext={this.state.total_classes_error.length === 0 ? "" : this.state.total_classes_error}
                        onChange={(event) => {
                            const me = this;
                            this.setState({
                                "total_classes": event.target.value,
                                "total_classes_error": "",
                                "isEdit":true
                            }, function () {
                                me.updateProposedQuoteComputation();
                                me.initialBaselineQuoteComputation();
                            })
                        }}
                        onMouseLeave={() => {
                            this.setState({
                                "total_classes": Number(this.state.total_classes),
                            })
                        }}
                        inputProps={{ min: 0 }}

                    />
                    {this.state.total_classes_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.total_classes_error}</Typography>}
                </FormControl>
            </React.Fragment>
        )

        const idealAndRecommend = (
            <Card>
                <Typography variant="h6">Recommended</Typography>
                <Table aria-label="table" className={classes.table} >
                    <TableHead className={classes.mainhead}>
                        <TableRow  >
                            <TableCell align="left" className={classes.tableHeader}><Typography >Name</Typography></TableCell>
                            <TableCell align="left" className={classes.tableHeader}><Typography >No of Units</Typography></TableCell>
                            <TableCell align="left" className={classes.tableHeader}><Typography >Final Price</Typography></TableCell>
                            <TableCell align="left" className={classes.tableHeader}><Typography >Remarks</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.baseline_quote.length === 0 && <TableRow><TableCell colSpan={14} style={{ textAlign: "center" }}><Typography >No Records Found</Typography></TableCell></TableRow>}
                        {this.state.baseline_quote.map((row, i) => {
                            if (editableFields.includes(row.name) || nonEditableFields.includes(row.name)) {
                                return <TableRow key={i} className={classes.tableRow}>
                                    <TableCell align="left" className={classes.tabledata}>{row.name}</TableCell>
                                    <TableCell align="left" className={classes.tabledata}>{row.no_of_unit}</TableCell>
                                    <TableCell align="left" className={classes.tabledata}>{row.final_price}</TableCell>
                                    <TableCell align="left" className={classes.tabledata}>{row.remarks}</TableCell>
                                </TableRow>
                            } else {
                                return null
                            }
                        })}
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="3" className={classes.tabledata}><Typography variant="body1" >Total order value</Typography></TableCell>
                            {this.state.baseline_quote.length !== 0 && <TableCell align="left" className={classes.tabledata}>
                                {this.state.baseline_quote[this.state.baseline_quote.findIndex(item => item.name === 'Total order value')].final_price}
                            </TableCell>}
                        </TableRow>
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="3" className={classes.tabledata}><Typography variant="body1" >Price per student (inclusive of all taxes)</Typography></TableCell>
                            {this.state.baseline_quote.length !== 0 && <TableCell align="left" className={classes.tabledata}>
                                {this.state.baseline_quote[this.state.baseline_quote.findIndex(item => item.name === 'Price per student (inclusive of all taxes)')].final_price}
                            </TableCell>}
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        )

        const commentBox = (
            <Card className={classes.commentCard}>
                <Typography variant="h6">Comments</Typography>
                <Divider />
                <CardContent className={classes.commentContent}>
                    <List>
                        {this.state.comments.map((item, i) => {
                            return <ListItem key={i} className={classes.commentList}>
                                <ListItemText primary={item.comment} secondary={" " + item.name + " " + moment(item.createdAt).format("MMMM DD YYYY")} />
                            </ListItem>
                        })}
                    </List>
                </CardContent>
                <CardActionArea>
                </CardActionArea>
                <CardActions className={classes.commentAction} >
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">Add a Comment</InputLabel>
                        <OutlinedInput
                            type="text"
                            id="commentInput"
                            label="Add a Comment"
                            value={this.state.commentInput}
                            variant="outlined"
                            className={classes.commentInput}
                            multiline
                            rows={3}
                            onChange={(event) => {
                                this.setState({ "commentInput": event.target.value })
                            }}
                            inputProps={{ maxLength: 200 }}
                        />
                    </FormControl>
                    <Button variant="contained" color="primary"
                        onClick={() => {
                            let temp = {
                                "name": auth_user.name,
                                "comment": this.state.commentInput,
                                "createdAt": new Date()
                            }
                            this.state.comments.push(temp);
                            this.setState({ "comments": this.state.comments, "commentInput": "" ,isEdit:true})
                        }}
                    >
                        Add Comment
                    </Button>
                </CardActions>
            </Card>
        )

        const proposedQuote = (
            <Card>
                <Typography variant="h6">Proposed</Typography>
                <Table aria-label="table" className={classes.table} >
                    <TableHead className={classes.mainhead}>
                        <TableRow  >
                            <TableCell align="left" className={classes.tableHeader}><Typography >Name</Typography></TableCell>
                            <TableCell align="left" className={classes.tableHeader}><Typography >No of Units</Typography></TableCell>
                            <TableCell align="left" className={classes.tableHeader}><Typography >Final Price</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.proposed_quote.length === 0 && <TableRow><TableCell colSpan={14} style={{ textAlign: "center" }}><Typography >No Records Found</Typography></TableCell></TableRow>}
                        {this.state.proposed_quote.map((row, i) => {
                            if (nonEditableFields.includes(row.name)) {
                                return <TableRow key={i} className={classes.tableRow}>
                                    <TableCell align="left" className={classes.tabledata}>{row.name}</TableCell>
                                    <TableCell align="left" className={classes.tabledata} style={{ paddingLeft: "32px" }}>{row.no_of_unit}</TableCell>
                                    <TableCell align="left" className={classes.tabledata}>{row.final_price}</TableCell>
                                </TableRow>
                            } else if (editableFields.includes(row.name)) {
                                return <React.Fragment key={i}><TableRow className={classes.tableRow} >
                                    <TableCell align="left" className={classes.tabledata}>{row.name}</TableCell>
                                    <TableCell align="left" className={classes.tabledata}>
                                        <FormControl variant="outlined">
                                            <OutlinedInput
                                                type="number"
                                                id="totalClasses"
                                                value={row.no_of_unit}
                                                required
                                                variant="outlined"
                                                onChange={(event) => {
                                                    const me = this;
                                                    let input = event.target.value;
                                                    let proposed_quote = [...me.state.proposed_quote];
                                                    let currentItem = proposed_quote[proposed_quote.findIndex(item => item.name === row.name)];
                                                    currentItem.no_of_unit = input;
                                                    proposed_quote[proposed_quote.findIndex(item => item.name === row.name)] = currentItem;
                                                    me.setState({ "proposed_quote": proposed_quote,"isEdit":true })
                                                }}
                                                onMouseLeave={() => {
                                                    this.handleProposedQuoteInput(row)
                                                }}
                                                inputProps={{ min: 0 }}
                                            />
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="left" className={classes.tabledata}>{row.final_price}</TableCell>
                                </TableRow>
                                </React.Fragment>
                            } else {
                                return null
                            }
                        })}
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1">Total order value</Typography>
                            </TableCell>
                            {this.state.proposed_quote.length !== 0 && <TableCell align="left" className={classes.tabledata}>
                                {this.state.proposed_quote[this.state.proposed_quote.findIndex(item => item.name === 'Total order value')].final_price}
                            </TableCell>}
                        </TableRow>
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}><Typography variant="body1" >Price per student (inclusive of all taxes)</Typography></TableCell>
                            {this.state.proposed_quote.length !== 0 && <TableCell align="left" className={classes.tabledata}>
                                {this.state.proposed_quote[this.state.proposed_quote.findIndex(item => item.name === 'Price per student (inclusive of all taxes)')].final_price}
                            </TableCell>}
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        )

        const discountCard = (
            <Card className={classes.commentCard}>
                <Table aria-label="table" className={classes.table} >
                    <TableBody>
                        {this.state.userRole !== "User" && <TableRow className={classes.tableRow}>
                            <TableCell align="left" className={classes.tabledata}><Typography variant="body1" >Discount % offered on total order value</Typography></TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                    <OutlinedInput
                                        type="number"
                                        id="schoolContactPhone"
                                        value={this.state.discount_percent_on_total_order}
                                        required
                                        error={this.state.discount_percent_on_total_order_error.length !== 0}
                                        variant="outlined"
                                        helpertext={this.state.discount_percent_on_total_order_error.length === 0 ? "" : this.state.discount_percent_on_total_order_error}
                                        onChange={(event) => {
                                            const me = this;
                                            this.setState({
                                                "discount_percent_on_total_order": event.target.value,
                                                "discount_percent_on_total_order_error": "",
                                                "discount_percent_on_total_order_warning": "",
                                                "isEdit":true
                                            }, function () {
                                                me.discountComputation()
                                            })
                                        }}
                                        inputProps={{ min: 0, max: auth_user.role === "Manager" ? 8.5 : 37 }}
                                        onMouseLeave={() => {
                                            this.setState({
                                                "discount_percent_on_total_order": Number(this.state.discount_percent_on_total_order),
                                            })
                                        }}
                                    />
                                </FormControl>
                                {this.state.discount_percent_on_total_order_error.length !== 0 && <Typography variant="body1" className={classes.error}>{this.state.discount_percent_on_total_order_error}</Typography>}
                                {this.state.discount_percent_on_total_order_warning.length !== 0 && <Typography variant="body1" className={classes.warning}>{this.state.discount_percent_on_total_order_warning}</Typography>}
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>{this.state.total_discount_value}</TableCell>
                        </TableRow>}
                        {this.state.userRole !== "User" && <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >Total order value after discount</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.total_order_after_discount}
                            </TableCell>
                        </TableRow>}
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >Final price per student</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.final_price_per_student}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >Price Before Tax</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.price_before_tax}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >GST Amounnt</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.gst_amount}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >Total Amount Per Student</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.total_amount_per_student}
                            </TableCell>
                        </TableRow>
                        {this.state.userRole !== "User" && <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >Profit amount (before discount)</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.profit_before_discount}
                            </TableCell>
                        </TableRow>}
                        {this.state.userRole !== "User" && <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >Profit amount (after discount)</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.profit_after_discount}
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                <CardActions>
                    <Button type="submit" variant="contained" color="primary" className={classes.updateButton} onClick={() => {
                        this.updateQuote();
                    }}>
                        Update Quote
                    </Button>
                </CardActions>
            </Card>
        )

        const getStepContent = (step) => {
            switch (step) {
                case 0:
                    return schoolInfo
                case 1:
                    return idealAndRecommend
                case 2:
                    return <React.Fragment>{proposedQuote}{discountCard}</React.Fragment>
                case 3:
                    return commentBox
                default:
                    break;
            }
        }
        const maxSteps = steps.length;
        console.log("edit state", this.state)
        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid item lg={10} sm={9} xs={9}>
                            <Breadcrumbs aria-label="Breadcrumb" className={classes.breadcrumbs}>
                                <Link color="inherit" component={RouterLink} to='/app/quote' >
                                    <Typography>Quote</Typography>
                                </Link>
                                <Typography color="textPrimary">
                                    Edit
                                </Typography>
                                <Typography color="textPrimary" variant="body1">
                                    {this.state.quote_id}
                                </Typography>
                                {this.state.school_name.length !== 0 && <Typography color="textPrimary">
                                    {this.state.school_name}
                                </Typography>}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item lg={2} sm={3} xs={3}>
                            <Button variant="contained" color="secondary" className={classes.cancelButton} onClick={() => {
                                if(this.state.isEdit === true){
                                    this.setState({confirmModal:true})
                                }else{
                                    this.props.history.push("/app/quote")
                                }
                            }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>

                    <Hidden mdDown implementation="css">
                        {schoolInfo}
                        <Grid container spacing={2}>
                            <Grid item lg={6}>
                                {idealAndRecommend}
                                {commentBox}
                            </Grid>
                            <Grid item lg={6}>
                                {proposedQuote}
                                {discountCard}
                            </Grid>
                        </Grid>
                    </Hidden>
                    <React.Fragment>
                        <Hidden lgUp implementation="css">
                            {getStepContent(this.state.activeStep)}
                            <MobileStepper className={classes.stepper} activeStep={this.state.activeStep}
                                variant="text"
                                steps={maxSteps}
                                nextButton={
                                    <Button className={classes.button} size="large" onClick={this.handleNext} >
                                        {this.state.activeStep === steps.length - 1 ? "Update Quote" : "Next"}
                                    </Button>
                                }
                                backButton={
                                    <Button className={classes.button} size="large" onClick={this.handleBack} disabled={this.state.activeStep === 0}>
                                        Back
                                    </Button>
                                }
                            />
                        </Hidden>
                    </React.Fragment>
                </Paper>
                <ModalComponent 
                    open={this.state.confirmModal} 
                    title="Confirmation"
                    buttonText="Discard"
                    body="Are you sure you want to discard the changes ."
                    onClose={()=>{
                        this.setState({confirmModal:false})
                    }}
                    onSubmit={()=>{
                        this.props.history.push("/app/quote")
                    }}
                />
            </div>
        );
    }
}

export default compose(
    withStyles(useStyle)
)(connect((state, props) => {
    //State to Prop
    return {
        quoteReducer: state.quoteReducer,
        rateCardReducer: state.rateCardReducer,
        authReducer: state.authReducer,
    };
}, {
    update: QuoteAction.update,
    RateCardfetch: RateCardAction.fetch
})(QuoteUpdate))
