import axios from "axios";
import SnackBarAlertAction,{SnackBarAlertType} from "./snackBarAction";

const url = process.env.REACT_APP_URL;

export const GET_USER_START = "GET_USER_START";
export const GET_USER_END = "GET_USER_END";

export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_END = "FETCH_USER_END";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_END = "UPDATE_USER_END";

export const ADD_USER_START = "ADD_USER_START";
export const ADD_USER_END = "ADD_USER_END";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHNAGE_PASSWORD_END = "CHNAGE_PASSWORD_END";

export function fetch() {
    const authToken = window.sessionStorage.getItem("authToken", null);
    return (dispatch) => {
        dispatch({
            type: FETCH_USER_START,
        });
        axios.get(url + "/user/fetch",{
            headers: {
                Authorization: "Bearer " + authToken,
            },
        }).then(function (response) {
            const response_data = response.data;
            if (response.data !== undefined) {
                dispatch({
                    type: FETCH_USER_END,
                    payload: {
                        data: response_data.data,
                    },
                });
            } else {
                dispatch({
                    type: FETCH_USER_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));
            }
        }).catch(function (error) {
            console.log(error);
            if (
                error.response !== undefined &&
                error.response.data !== undefined &&
                error.response.data.message !== undefined
            ) {
                dispatch({
                    type: FETCH_USER_END,
                    payload: {
                        error: error.response.data.message,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert(error.response.data.message,SnackBarAlertType.ERROR));
            } else {
                dispatch({
                    type: FETCH_USER_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));
            }
        });
    }
}

export function update(id,data) {
    const authToken = window.sessionStorage.getItem("authToken", null);
    return (dispatch) => {
        dispatch({
            type: UPDATE_USER_START,
        });
        axios.put(url + "/user/"+id,data,{
            headers: {
                Authorization: "Bearer " + authToken,
            },
        }).then(function (response) {
            const response_data = response.data;
            if (response.data !== undefined) {
                dispatch({
                    type: UPDATE_USER_END,
                    payload: {
                        data: response_data.data,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("User Updated.",SnackBarAlertType.SUCCESS));
            } else {
                dispatch({
                    type: UPDATE_USER_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));

            }
        }).catch(function (error) {
            console.log(error);
            if (
                error.response !== undefined &&
                error.response.data !== undefined &&
                error.response.data.message !== undefined
            ) {
                dispatch({
                    type: UPDATE_USER_END,
                    payload: {
                        error: error.response.data.message,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert(error.response.data.message,SnackBarAlertType.ERROR));
            } else {
                dispatch({
                    type: UPDATE_USER_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));
            }
        });
    }
}

export function add(data) {
    const authToken = window.sessionStorage.getItem("authToken", null);
    return (dispatch) => {
        dispatch({
            type: ADD_USER_START,
        });
        axios.post(url + "/user/create",data,{
            headers: {
                Authorization: "Bearer " + authToken,
            },
        }).then(function (response) {
            const response_data = response.data;
            if (response.data !== undefined) {
                dispatch({
                    type: ADD_USER_END, 
                    payload: {
                        data: response_data.data,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("User Added.",SnackBarAlertType.SUCCESS));
            } else {
                dispatch({
                    type: ADD_USER_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));

            }
        }).catch(function (error) {
            console.log(error);
            if (
                error.response !== undefined &&
                error.response.data !== undefined &&
                error.response.data.message !== undefined
            ) {
                dispatch({
                    type: ADD_USER_END,
                    payload: {
                        error: error.response.data.message,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert(error.response.data.message,SnackBarAlertType.ERROR));
            } else {
                dispatch({
                    type: ADD_USER_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));
            }
        });
    }
}

export function changePassword(data) {
    const authToken = window.sessionStorage.getItem("authToken", null);
    return (dispatch) => {
        dispatch({
            type: CHANGE_PASSWORD_START,
        });
        axios.put(url + "/user/change/password",data,{
            headers: {
                Authorization: "Bearer " + authToken,
            },
        }).then(function (response) {
            const response_data = response.data;
            if (response.data !== undefined) {
                dispatch({
                    type: CHNAGE_PASSWORD_END, 
                    payload: {
                        data: response_data.data,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Password Updated.",SnackBarAlertType.SUCCESS));
            } else {
                dispatch({
                    type: CHNAGE_PASSWORD_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));

            }
        }).catch(function (error) {
            console.log(error);
            if (
                error.response !== undefined &&
                error.response.data !== undefined &&
                error.response.data.message !== undefined
            ) {
                dispatch({
                    type: CHNAGE_PASSWORD_END,
                    payload: {
                        error: error.response.data.message,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert(error.response.data.message,SnackBarAlertType.ERROR));
            } else {
                dispatch({
                    type: CHNAGE_PASSWORD_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));
            }
        });
    }
}