import { createStyles } from '@material-ui/core/styles';

export const useStyle = function (theme) {
    const {palette,breakpoints}     =   theme;

    return createStyles({
        root: {
            width: '100%',
            padding: '15px',
            overflow:"auto",
            [breakpoints.down('sm')]:{
                padding:"0px"
            },
        },
        paper: {
            width: '100%',
            padding: '15px',
            [breakpoints.down('sm')]:{
                overflow:"scroll",
            },
        },
        title: {
            fontWeight: 600,
            display:"inline"
        },
        headDiv: {
            marginBottom:"15px"
        },
        mainhead: {
            background:palette.secondry.main
        },
        tableHeader: {
            // color: "#ffff",
            fontWeight: '600',
            fontSize: '14px'
        },
        tabledata: {
            // padding: "0px 0px 0px 16px"
        },
        addButton:{
            float:"right"
        }
    })
}