import React from "react";
import { connect } from "react-redux";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Paper, Button, TableContainer
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { compose } from "recompose";
import { useStyle } from "./style";
import * as UserAction from "../../../../service/action/user";
class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'userList': [],
        };
    }

    componentDidMount() {
        this.props.fetch()
    }

    //pagination code for future use
    // handleChangePage(event, newPage) {
    //     this.setState({ 'page': newPage }, function () {
    //         // this.fetch();
    //     })
    // }
    // handleChangeRowsPerPage(event) {
    //     var me = this;
    //     me.state.filter.page_size = parseInt(event.target.value, 10);
    //     me.setState({ rowsPerPage: parseInt(event.target.value, 10) }, function () {
    //         // me.fetch();
    //     })
    // }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.userReducer.fetchProg === true &&
            nextProps.userReducer.fetchProg === false
        ) {
            if (nextProps.userReducer.error === null) {
                let data = nextProps.userReducer.users;
                this.setState({ userList: data, count: data.length });
            }
        } else {
            /* eslint-disable no-unused-vars */
            let error = nextProps.userReducer.error;
            /* eslint-enable no-unused-vars */
        }
    }

    render() {
        let classes = this.props.classes;
        const authUser = this.props.authReducer.auth_user;
        if (authUser === null) {
            return null
        }

        return (
            <div className={classes.root}>
                <Paper elevation={3} className={classes.paper}>
                    <div className={classes.headDiv} >
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            Sales User
                        </Typography>
                        <Button variant="contained" color="primary" className={classes.addButton} onClick={() => {
                            this.props.history.push("/app/user/create")
                        }}>
                            Add Sales User
                        </Button>
                    </div>
                    <TableContainer>
                        <Table aria-label="table" className={classes.table} >
                            <TableHead className={classes.mainhead}>
                                <TableRow  >
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Name</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Email</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Role</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Login ID</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Status</Typography></TableCell>
                                    <TableCell align="left" className={classes.tableHeader}><Typography >Phone</Typography></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.userList.length === 0 && <TableRow><TableCell colSpan={14} style={{ textAlign: "center" }}><Typography >No Records Found</Typography></TableCell></TableRow>}
                                {this.state.userList.map((row, i) => {
                                    return <TableRow key={i} style={{ cursor: "pointer" }} onClick={() => {
                                        if (authUser.role === "Manager" && row.role !== "Admin" && row.role !== "Manager") {
                                            this.props.history.push("/app/user/" + row._id, row);
                                        } else if (authUser.role === "Admin") {
                                            this.props.history.push("/app/user/" + row._id, row);
                                        }
                                    }}>
                                        <TableCell align="left" className={classes.tabledata}>{row.name}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.email}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.role}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.loginId}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.status}</TableCell>
                                        <TableCell align="left" className={classes.tabledata}>{row.phone}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>

                            {/* pagination code for future use */}
                            {/* <TableFooter>
                            <TablePagination
                                classes={{ root: classes.paginate }}
                                rowsPerPageOptions={[5, 10, 15]}
                                colSpan={14}
                                count={state.count}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationModel}
                            />
                        </TableFooter> */}
                        </Table>
                    </TableContainer>
                </Paper>
            </div >
        );
    }
}

export default compose(
    withStyles(useStyle)
)(connect((state, props) => {
    //State to Prop
    return {
        userReducer: state.userReducer,
        authReducer: state.authReducer,
    };
}, {
    fetch: UserAction.fetch
})(UserList))
