import { createStyles } from '@material-ui/core/styles';

export const useStyle = function (theme) {
  const {palette,breakpoints}     =   theme;

  const drawerWidth   =  180; 

  return createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      // marginTop:"63px"
    },
    toolbar: {
      // height:"64px",
      textAlign: "center",
      [breakpoints.up("lg")]:{
        minHeight:"64px"  
      },
    },
    typo: {
      padding: "22px",
      color: palette.primary.main,
      [breakpoints.up("lg")]:{
        display:"none"  
      },
    },
    active: {
      backgroundColor: "#70BD72 !important"
    }
  })
}