import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import theme from "./theme";
import Login from "./Component/Login";
import Home from "./Component/Application";
import SnackbarAlert from './Component/Application/common/snackBarAlert';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    console.log("env",process.env.REACT_APP_ENV)
    var user_theme = theme();
    return (<ThemeProvider theme={user_theme}>
      <Switch>
        <Route path={"/login"} component={Login} />
        <Route path={"/app"} component={Home} />
        <Redirect exact path={"/"} to={`/login`} />
      </Switch>
      <SnackbarAlert />
    </ThemeProvider>
    );
  }
}

export default App;
